import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Form, Input, Button, DropdownMenu, Spinner } from "reactstrap";
import { json, useLocation, useNavigate } from "react-router";
import "./style.module.css";
import { getAllCategories, getAllCities, getAllCompanies } from "../../http/get/getApi";
import { useQuery } from "react-query";
import Cookies from "js-cookie";
// import vidHM from "../../images/vidHM.mp4";
import { BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";
import FrontPageLogo from "../../images/IPartyDJ_MIX.png";
import { createcontext } from "../../layout/Index";
import { getCookie } from "../../utils/Utils";
import debounce from "lodash.debounce";
import { ShimmerThumbnail } from "react-shimmer-effects";
import LogoFetch from "../../layout/header/LogoFetch";
import { Links } from "../../jsonfiles/Links";
import Home from "../../jsonfiles/Home.json";
import CookieSiteSettings from "../../utils/cookieUtils";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const HeroSection = () => {

  const activeVariable = getActiveEnvironmentVariable();



  const vidHM = Links?.[activeVariable]?.Home?.[0]?.url2;
  const location = useLocation();
  const [input, setInput] = useState("");
  const [fitlerCategory, setFitlerCategory] = useState("");
  const [showEventTypeSelect, setShowEventTypeSelect] = useState(false);
  const [showCitySelect, setShowCitySelect] = useState(false);
  const [city, setCity] = useState("");
  const [cityData, setCityData] = useState([]);
  const [citySelected, setCitySelected] = useState("");
  const navigate = useNavigate();
  const eventTypeDropdownRef = useRef(null);
  const cityDropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState(false);
  const timeoutRef = useRef();
  const [lockScreen, setLockScreen] = useState(false);
  const [mainScreen, setMainScreen] = useState(false);

  const { heroSection, setHeroSection } = useContext(createcontext);
  const [categoryData, setCategoryData] = useState([]);

  let latLongCityData = getCookie("latLongCity");
  useEffect(() => {
    if (latLongCityData) {
      setCityData(latLongCityData);
    }
  }, []);

  

  // const {
  //   data: categoryList,
  //   isLoading: categoryListLoading,
  //   isError: categoryListError,
  // } = useQuery({
  //   queryKey: ["get-category-list"],
  //   queryFn: () => getAllCategories(),
  // });

  useEffect(() => {
    const fetchCategory = async () => {
      const categoryList = await getAllCategories();
      setCategoryData(categoryList?.data?.Category);
    };
    fetchCategory();
  }, []);

  const {
    data: cityList,
    isLoading: cityListLoading,
    isError: cityListError,
  } = useQuery({
    queryKey: ["get-city-list", cityData],
    queryFn: () => getAllCities({ city: cityData }),
    staleTime: Infinity,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (eventTypeDropdownRef.current && !eventTypeDropdownRef.current.contains(event.target)) {
        setShowEventTypeSelect(false);
      }
      if (cityDropdownRef.current && !cityDropdownRef.current.contains(event.target)) {
        setShowCitySelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputClick = (inputType) => {
    if (inputType === "CategoryType") {
      setShowEventTypeSelect(true);
      setShowCitySelect(false);
    } else if (inputType === "city") {
      setShowCitySelect(true);
      setShowEventTypeSelect(false);
    }
  };

  const handleSelect = (name) => {
    setInput(name.title);
    setFitlerCategory(name.id);
    setShowEventTypeSelect(false);
  };

  const handleCitySelect = (name) => {
    console.log(name);
    const cityNameWithState = `${name?.city_name}, ${name?.state_name}`;
    setCitySelected(cityNameWithState);
    setCity(name?.city_name);
    setShowCitySelect(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const fetchedCompaniesList = await getAllCompanies({
        type: 10,
        offset: 1,
        category: fitlerCategory,
        city: city,
      });
      setLoading(false);
      navigate("/dj-profiles/1", { state: { companiesList: fetchedCompaniesList, fitlerCategory, city } });
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("cityData", citySelected, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      Cookies.set("cityId", city, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      Cookies.remove("latLongCity");
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    setCitySelected(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setCityData(value ? value : latLongCityData);
    }, 1000);
  };

  const handleHeroSection = () => {
    setHeroSection(true);
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("Herosection", "true", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  };

  useEffect(() => {
    const heroCokkie = getCookie("Herosection");
    if (heroCokkie === "true") {
      setHeroSection(true);
      setMainScreen(true);
      setLockScreen(false);
    } else {
      setHeroSection(false);
      setMainScreen(false);
      setLockScreen(true);
    }
  }, [heroSection]);
  
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    const fetchTitle = async () => {
      const siteSettings = CookieSiteSettings();
      if (siteSettings) {
        setTitle(siteSettings?.other?.landing_page_title);
      }
      setLoading(false);
    };

    fetchTitle();
  }, []);
  const fetchLogoFromCookie = () => {
    const siteSettings = CookieSiteSettings();
    return siteSettings?.meta?.sidebar_logo || null;
  };
  useEffect(() => {
    setLogo(fetchLogoFromCookie());
    setLoading(false);

    const interval = setInterval(() => {
      const newLogo = fetchLogoFromCookie();
      if (newLogo !== logo) {
        setLogo(newLogo);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [logo]);

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: "90.5vh",
          position: "relative",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
        >
          <source src={vidHM} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {mainScreen === true && (
          <div className="text-center p-4 w-70" style={{ zIndex: 1 }}>
            {/* {loading ? <div className="p-5">
              <ShimmerThumbnail height={100} width={850} />
            </div> :  */}
            <h1 className="mb-4 text-center font-weight-bold text-white d-none d-md-block">
              {title &&
                title.split(" ").map((word, index) => (
                  <span key={index} style={{ color: word === "Ultimate" ? "red" : "inherit" }}>
                    {word}{" "}
                  </span>
                ))}
            </h1>
            <h1 className="mb-4 text-center font-weight-bold text-white d-md-none fs-6">
              {title &&
                title.split(" ").map((word, index) => (
                  <span key={index} style={{ color: word === "Ultimate" ? "red" : "inherit" }}>
                    {word}{" "}
                  </span>
                ))}
            </h1>
            {/* } */}

            <div className=" bg-white py-3 px-3 rounded-3 ">
              
                <p className="mb-2 fs-5 d-none d-md-block">
                  
                  {Home?.[activeVariable]?.section1?.map((item) => item?.title1)}
                  </p>
                <p className="mb-0 fs-7 d-md-none">
                  
                  {Home?.[activeVariable]?.section1?.map((item) => item?.title1)}
                  </p>
              
              <Form className="mb-3">
                <Row className="align-items-end gx-0">
                  <Col md={5}>
                    <Input
                      style={{ cursor: "pointer" }}
                      size="xl"
                      type="text"
                      value={input}
                      className="rounded-start flex-grow-1 rounded-0 my-1 d-none d-md-block"
                      placeholder={Home?.[activeVariable]?.section6?.map((item) => item?.title5)}      
                      onClick={() => handleInputClick("CategoryType")}
                      onChange={(e) => {
                        setInput(e.target.value);
                        setDropDownData(true);
                      }}
                    />
                    <Input
                      style={{ cursor: "pointer" }}
                      size="sm"
                      type="text"
                      value={input}
                      className="rounded-start flex-grow-1 rounded-0 my-1 d-md-none"
                      placeholder= {Home?.[activeVariable]?.section6?.map((item) => item?.title5)}
                      onClick={() => handleInputClick("CategoryType")}
                      onChange={(e) => {
                        setInput(e.target.value);
                        setDropDownData(true);
                      }}
                    />
                    {showEventTypeSelect && (
                      <div
                        ref={eventTypeDropdownRef}
                        className="position-absolute h-200px overflow-auto heromanual-dropdown"
                        style={{ zIndex: 1, width: "27%" }}
                      >
                        <DropdownMenu className="d-flex flex-wrap p-1 border top-0">
                          {/* {categoryListLoading ? (
                            <div className="mx-1 align-center">
                              <Spinner size="sm" color="light" />
                              <span className="px-1">Loading...</span>
                            </div>
                          ) : categoryListError ? (
                            <div>Error loading category. Please try again.</div>
                          ) : categoryList?.data?.Category?.length ? (
                            categoryList?.data?.Category?.map((category, index) => (
                              <div
                                key={index}
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSelect(category)}
                              >
                                {category.title}
                              </div>
                            ))
                          ) : (
                            <div>No Category Found</div>
                          )} */}
                          {categoryData?.length ? (
                            categoryData?.map((category, index) => (
                              <div
                                key={index}
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSelect(category)}
                              >
                                {category.title}
                              </div>
                            ))
                          ) : (
                            <div>No Category Found</div>
                          )}
                        </DropdownMenu>
                      </div>
                    )}
                  </Col>
                  <Col md={5}>
                    <Input
                      style={{ cursor: "pointer" }}
                      size="xl"
                      type="text"
                      value={citySelected}
                      onClick={() => handleInputClick("city")}
                      onChange={handleCityChange}
                      className="flex-grow-1 rounded-0 my-1 d-none d-md-block"
                      placeholder="City"
                    />
                    <Input
                      style={{ cursor: "pointer" }}
                      size="sm"
                      type="text"
                      value={citySelected}
                      onClick={() => handleInputClick("city")}
                      onChange={handleCityChange}
                      className="flex-grow-1 rounded-0 my-1 d-md-none"
                      placeholder="City"
                    />
                    {showCitySelect && (
                      <div
                        ref={cityDropdownRef}
                        className="position-absolute h-200px overflow-auto heromanual-dropdown"
                        style={{ zIndex: 1, width: "27%" }}
                      >
                        <DropdownMenu className="d-flex flex-wrap p-1 border w-100 top-0">
                          {cityListLoading ? (
                            <div className="mx-1 align-center">
                              <Spinner size="sm" color="light" />
                              <span className="px-1">Loading...</span>
                            </div>
                          ) : cityListError ? (
                            <div>Error loading cities. Please try again.</div>
                          ) : cityList?.data?.cities_with_states?.length ? (
                            cityList.data.cities_with_states.map((name, index) => (
                              <div
                                key={name.city_id || index}
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCitySelect(name)}
                              >
                                {/* {console.log(cityList?.data?.cities_with_states)} */}
                                {name.city_name}, {name.state_name}
                              </div>
                            ))
                          ) : (
                            <div>No Cities Found</div>
                          )}
                        </DropdownMenu>
                      </div>
                    )}
                  </Col>
                  <Col md={2}>
                    <div className="d-none d-md-block">
                      <Button
                        color="primary"
                        className="rounded-end flex-grow-1 rounded-0 fs-6 my-1 btn-lg"
                        block
                        onClick={handleSearch}
                        disabled={loading}
                        style={{ padding: "10px 5px", height: "55px" }}
                      >
                        {loading ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          <div>
                           {Home?.[activeVariable]?.section1?.map((item) => item?.title4)}
                          </div>
                        )}
                      </Button>
                    </div>
                    <div className="d-md-none">
                      <Button
                        color="primary"
                        className="rounded-end flex-grow-1 rounded-0 fs-6 my-1 btn-sm"
                        block
                        onClick={handleSearch}
                        disabled={loading}
                        style={{ padding: "10px 5px", height: "40px" }}
                      >
                        {loading ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          <div>
                            
                          {Home?.[activeVariable]?.section1?.map((item) => item?.title4)}
                          </div>
                        )}
                      </Button>
                    </div>

                    {/* <Button
                    size="xl"
                      color="primary"
                      className="rounded-end flex-grow-1 rounded-0 fs-6 my-1 "
                      block
                      onClick={handleSearch}
                      disabled={loading}
                      style={{ padding: "10px 5px", height: "55px" }}
                    >
                      {loading ? <Spinner size="sm" color="light" /> : "Search DJs"}
                    </Button> */}
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        )}
        {lockScreen === true && (
          <div style={{ zIndex: 1 }} className="flex-column align-center justify-center">
            <div className="my-1 mb-2 h-150px">
              {loading ? (
                <div className="p-5">
                  <ShimmerThumbnail height={100} width={150} />
                </div>
              ) : (
                <img
                  className=""
                  src={`${process.env.REACT_APP_API_URL}` + logo}
                  alt="IPDM Logo"
                  style={{ height: "inherit" }}
                />
              )}
            </div>
            <BlockTitle className="justify-center text-white">
              <span style={{ fontSize: "25px" }}>
                {Home?.[activeVariable]?.section1?.map((item) => item?.title3)}
              </span>
            </BlockTitle>

            <div className="justify-center py-1">
              <Button
                className="fs-2 p-4 px-5"
                color="primary"
                style={{ borderRadius: "50px" }}
                onClick={handleHeroSection}
              >
                Search Now
              </Button>
            </div>
            <div className="d-flex justify-center">
              <span className="text-white fs-4 fw-semibold">
                
                           {Home?.[activeVariable]?.section1?.map((item) => item?.title2)}
                          </span>
              <Link to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=business`}>
                <span className="text-primary fs-4 fw-semibold ms-1">Sign Up Here!</span>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HeroSection;
