import React, { useEffect, useRef, useState } from "react";
import { CardBody, Form } from "reactstrap";
import Carousel from "react-multi-carousel";
import { Block, BlockHead, BlockHeadContent, BlockTitle } from "../../components/block/Block";
import { PreviewCard } from "../../components/preview/Preview";
import {
  Badge,
  Card,
  DropdownMenu,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import Icon from "../../components/icon/Icon";
import classnames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router";
import Content from "../../layout/content/Content";
import { Button, Col, Row, RSelect } from "../../components/Component";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { formatDate, formatDates, getCookie } from "../../utils/Utils";
import {
  getAllCategories,
  getAttire,
  getAvailableSlot1,
  getmusics,
  getPackages,
  getVenue,
} from "../../http/get/getApi";
import { useQuery } from "react-query";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import djImage from "../../images/DJ_Section2.png";
import Required from "../components/Required";
import CalenderApp from "../../components/partials/calender/Calender";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import NoFounds from "../../images/Login-No-Background.png";
import djdashboard from "../../images/Equipment Rental.jpg";
import { Link } from "react-router-dom";
import book from "../../jsonfiles/Booking.json";
import dj from "../../jsonfiles/Dj.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const DJBookingEvent = () => {
  const { id } = useParams();

  const activeVariable = getActiveEnvironmentVariable();

 

  const location = useLocation();
  const [modalTop, setModalTop] = useState(false);
  const toggleTop = () => setModalTop(!modalTop);
  const navigate = useNavigate();
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [formData, setFormData] = useState({
    eventname: "",
    eventType: "",
    attire: "",
    guestcount: "",
    eventdate: "",
    setuptime: null,
    starttime: null,
    endtime: null,
    eventvenue: "",
    packageid: "1",
    packageamount: "",
    retainer: "",
    retainerpaymentamount: "",
    schpayment2: "",
    sch2paymentamount: "",
    schpayment3: "",
    sch3paymentamount: "",
    servicesarr: [],
  });
  const [showFirstModal, setShowFirstModal] = useState(true);

  const handleOpenSecondModal = () => {
    setShowFirstModal(false);
  };
  const resetForm = () => {
    setFormData({
      eventname: "",
      eventType: "",
      attire: "",
      guestcount: "",
      eventdate: "",
      setuptime: "",
      starttime: "",
      endtime: "",
      eventvenue: "",
    });
  };
  const { eventDate, startTime, endTime, companyData } = location.state || {};
  const [eventdate, setEventdate] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [formDataSlot, setFormDataSlot] = useState({
    startTime: null,
    endTime: null,
    eventDate: null,
    start: null,
    end: null,
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [activeIconTab, setActiveIconTab] = useState("1");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const [loading, setLoading] = useState(false);
  const [eventDateSelected, setEventDateSelected] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState();
  const [startTimeErrorChk, setStartTimeErrorChk] = useState("");
  const [endTimeErrorChk, setEndTimeErrorChk] = useState("");
  const [eventDateErrorChk, seteEventDateErrorChk] = useState("");
  const [scheFirstErrorChk, setScheFirstErrorChk] = useState("");
  const [scheLastErrorChk, setScheLastErrorChk] = useState("");
  const [pkgErrorChk, setPkgErrorChk] = useState("");
  const [addEventId, setAddEventId] = useState("");
  const [showMusicSelect, setShowMusicSelect] = useState(false);
  const [musicSelected, setMusicSelected] = useState([]);
  const [musicDataTitle, setMusicDataTitle] = useState([]);
  const [music, setMusic] = useState("");
  const musictimeoutRef = useRef();
  const [modalSmall, setModalSmall] = useState(false);
  const toggleSmall = () => setModalSmall(!modalSmall);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const username = Cookies.get("username");
  const {
    data: categoryList,
    isLoading: categoryListLoading,
    isError: categoryListError,
  } = useQuery({
    queryKey: ["get-category-list"],
    queryFn: () => getAllCategories(),
    staleTime: Infinity,
  });

  const {
    data: attireList,
    isLoading: attireListLoading,
    isError: attireListError,
  } = useQuery({
    queryKey: ["get-attire-list"],
    queryFn: () => getAttire(),
    staleTime: Infinity,
  });

  const {
    data: venueList,
    isLoading: venueListLoading,
    isError: venueListError,
  } = useQuery({
    queryKey: ["get-venue-list"],
    queryFn: () => getVenue(),
    staleTime: Infinity,
  });

  const {
    data: packageList,
    isLoading: packageListLoading,
    isError: packageListtError,
  } = useQuery({
    queryKey: ["get-package-list", id],
    queryFn: () => getPackages({ id: id }),
    staleTime: Infinity,
  });

  const {
    data: slotList,
    isLoading: slotListLoading,
    isError: slotListtError,
  } = useQuery({
    queryKey: ["get-slot-list", id],
    queryFn: () => getAvailableSlot1({ id: id }),
    staleTime: Infinity,
  });

  const {
    data: musicList,
    isLoading: musicListLoading,
    isError: musicListError,
  } = useQuery({
    queryKey: ["get-music-list", music],
    queryFn: () => getmusics({ music: music }),
    staleTime: Infinity,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
  } = useForm();

  const next = parseInt(activeIconTab) + 1;
  const back = parseInt(activeIconTab) - 1;

  useEffect(() => {
    // const start = setSelectedDate(startTime, eventDate);
    // const end = setSelectedDate(endTime, eventDate);
    if (startTime && endTime) {
      setFormData((prevState) => ({
        ...prevState,
        eventdate: eventDate,
        starttime: startTime,
        endtime: endTime,
      }));
    }
  }, [eventDate, startTime, endTime]);

  const setSelectedDate = (responseTime, selectedDate) => {
    if (!responseTime || !selectedDate) {
      return "";
    }
    const timePart = new Date(responseTime);
    const datePart = new Date(selectedDate);
    if (isNaN(timePart.getTime()) || isNaN(datePart.getTime())) {
      return "";
    }
    const hours = timePart.getHours();
    const minutes = timePart.getMinutes();
    const seconds = timePart.getSeconds();
    datePart.setHours(hours, minutes, seconds);
    return datePart.toLocaleString("en-GB").replace(",", "");
  };

  useEffect(() => {
    if (formSubmitted) {
      if (activeIconTab !== 4) {
        setActiveIconTab(next.toString());
      }
      setFormSubmitted(false);
    }
  }, [formSubmitted, next]);

  useEffect(() => {
    setFormData({
      ...formData,
      packageid: "",
      retainerpaymentamount: "",
      sch2paymentamount: "",
      sch3paymentamount: "",
    });
  }, []);

  const handleSaveAndNext = () => {
    setFormSubmitted(true);
  };

  const handleTerms = (e) => {
    setAcceptedTerms(true);
  };

  const cokkie = getCookie("role");
  const eventSubmit = async () => {
    let hasError = false;
    seteEventDateErrorChk("");
    setEndTimeErrorChk("");
    setStartTimeErrorChk("");

    if (!formData.eventdate) {
      seteEventDateErrorChk("This field is required.");
      hasError = true;
    }
    if (!formData.starttime) {
      setStartTimeErrorChk("This field is required.");
      hasError = true;
    }
    if (!formData.endtime) {
      setEndTimeErrorChk("This field is required.");
      hasError = true;
    }
    if (hasError) {
      return;
    }
    if (cokkie === "2") {
      
      Swal.fire({
        title:dj?.[activeVariable]?.section7?.map((item) => item?.title1),
        
        html: `<span style="font-weight: 800">${username}</span>
        ${dj?.[activeVariable]?.section7?.map((item) => item?.title2)}
        
        <br/>
        ${dj?.[activeVariable]?.section7?.map((item) => item?.title3)}`,
        imageUrl: djdashboard,
        imageWidth: 300,
        imageHeight: 200,
        imageAlt: dj?.[activeVariable]?.section7?.map((item) => item?.title9),
        confirmButtonText:dj?.[activeVariable]?.section7?.map((item) => item?.title4),
        cancelButtonText:dj?.[activeVariable]?.section7?.map((item) => item?.title5),
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}add-event`;
          return;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          navigate(`/dj-profiles/specificDj/${id}`); // Navigate to specific DJ profile if canceled
        }
      });
      return;
    }
    setLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("company_id", id);
      formdata.append("event_name", formData.eventname);
      formdata.append("event_type", formData.eventType);
      if (formData.setuptime) {
        formdata.append("setup_time", formData.setuptime === null ? null : formatDates(formData.setuptime));
      }
      const exactStartTime = adjustTimeWithStartDate(formData.starttime, formData.eventdate);
      formdata.append("start_date_time", formData.starttime === null ? null : formatDates(exactStartTime));
      const exactEndTime = adjustTimeWithStartDate(formData.endtime, formData.eventdate);
      formdata.append("end_date_time", formData.endtime === null ? null : formatDates(exactEndTime));
      formdata.append("attire", formData.attire);
      formdata.append("guest_count", formData.guestcount);
      formdata.append("venue_id", formData.eventvenue);
      const eventdata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/book-event`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: formdata,
      });
      const formatedData = await eventdata.json();
      if (formatedData && formatedData.status) {
        setAddEventId(formatedData?.data?.id);
        handleSaveAndNext();
      } else {
        toast.error(formatedData.message);
        console.log(formatedData.message);
      }
    } catch (err) {
      console.log("Error :" + err);
    } finally {
      setLoading(false);
    }
  };

  const eventEditSumbmit = async () => {
    let hasError = false;
    seteEventDateErrorChk("");
    setEndTimeErrorChk("");
    setStartTimeErrorChk("");
    // setPkgErrorChk("");

    if (!formData.eventdate) {
      seteEventDateErrorChk("Event date is required.");
      hasError = true;
    }
    if (!formData.starttime) {
      setStartTimeErrorChk("Start time is required.");
      hasError = true;
    }
    if (!formData.endtime) {
      setEndTimeErrorChk("End time is required.");
      hasError = true;
    }
    if (hasError) {
      return;
    }

    if (formData.sch2paymentamount && !formData.schpayment2) {
      setScheFirstErrorChk(true);
      setLoading(false);
      return;
    }

    if (formData.sch3paymentamount && !formData.schpayment3) {
      setScheLastErrorChk(true);
      setLoading(false);
      return;
    }
    if (activeIconTab === "3" && !formData.packageid) {
      setPkgErrorChk("Package is required.");
      return;
    }
    if (formData.eventdate < formData.schpayment2) {
      return;
    }

    setLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("event_name", formData.eventname);
      formdata.append("event_type", formData.eventType);
      formdata.append("setup_time", formData.setuptime === null ? null : formatDates(formData.setuptime));
      formdata.append("start_date_time", formData.starttime === null ? null : formatDates(formData.starttime));
      formdata.append("end_date_time", formData.endtime === null ? null : formatDates(formData.endtime));
      formdata.append("attire", formData.attire);
      formdata.append("guest_count", formData.guestcount);
      formdata.append("venue_id", formData.eventvenue);
      formdata.append("company_id", id);
      formdata.append("event_id", addEventId);
      if (formData.servicesarr) {
        formdata.append("services", JSON.stringify(formData.servicesarr));
      }
      if (musicDataTitle) {
        const musicDataIds = musicDataTitle.map((music) => music?.id);
        formdata.append("musics", JSON.stringify(musicDataIds));
      }
      if (formData.packageid) {
        formdata.append("package_id", formData.packageid);
      }
      if (formData.schpayment2) {
        formdata.append("scheduled_payment_2", formatDates(formData.schpayment2));
      }
      if (formData.sch2paymentamount) {
        formdata.append("scheduled_payment_2_value", formData.sch2paymentamount);
      }
      if (formData.schpayment3) {
        formdata.append("scheduled_payment_3", formatDates(formData.schpayment3));
      }
      if (formData.sch3paymentamount) {
        formdata.append("scheduled_payment_3_value", formData.sch3paymentamount);
      }
      const eventdata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/update-book-event`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: formdata,
      });

      const formatedData = await eventdata.json();
      if (activeIconTab === "1" || activeIconTab === "2") {
        handleSaveAndNext();
        setLoading(false);
        return;
      }
      if (formatedData && formatedData.status) {
        resetForm();
        setAddEventId("");
        setActiveIconTab("1");
        showPopupMessege();
      } else {
        console.log(formatedData.message);
        toast.error(formatedData.message);
      }
    } catch (err) {
      console.error("Error: " + err);
      toast.error("An error occurred while submitting the event. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showPopupMessege = () => {
    Swal.fire({
      title: dj?.[activeVariable]?.section7?.map((item) => item?.title6),
      html: `<span style="font-weight: 800">${username}</span> can visit your <a href=${process.env.REACT_APP_CLIENT_URL} target=_blank style="text-decoration: none;">Dashboard</a> ${dj?.[activeVariable]?.section7?.map((item) => item?.title7)} <br/> ${dj?.[activeVariable]?.section7?.map((item) => item?.title8)} <a href="/dj-profiles/specificDj/${id}" style="text-decoration: none;">${companyData?.data?.[0]?.company_name}</a> DJ profile?`,
      imageUrl: djImage,
      imageWidth: 450,
      imageHeight: 300,
      imageAlt: "djImage",
      confirmButtonText: dj?.[activeVariable]?.section7?.[9]?.title10,
      // showIpdm && dj?.CRM?.section7?.[9]?.title10 || showPhotography && dj?.photography?.section7?.[9]?.title10 || showConstruction && dj?.construction?.section7?.[9]?.title10 ,
      cancelButtonText: dj?.[activeVariable]?.section7?.[10]?.title10,
      // showIpdm &&  dj?.CRM?.section7?.[10]?.title11 || showPhotography && dj?.photography?.section7?.[10]?.title11 || showConstruction && dj?.construction?.section7?.[10]?.title11,
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-info",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        return navigate(`/dj-profiles/1`);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        navigate(`/dj-profiles/specificDj/${id}`); // Navigate to specific DJ profile if canceled
      }
    });
  };

  const handleEventType = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      eventType: value,
    }));
    clearErrors(name);
  };

  const handleAttire = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      attire: value,
    }));
    clearErrors(e.target.name);
  };

  const handleVenues = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      eventvenue: value,
    }));
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const handleSetEventDate = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      eventdate: date,
    }));
    setEventDateSelected(true);
  };

  const adjustTimeWithStartDate = (time, startDate) => {
    if (startDate || time) {
      const adjustedTime = new Date(startDate);
      adjustedTime.setHours(time.getHours());
      adjustedTime.setMinutes(time.getMinutes());
      return adjustedTime;
    }
  };

  const handlePackagePrice = (e) => {
    const value = parseInt(e.target.value);
    const selectedPackage = packageList?.data?.packages.find((item) => item?.id === value);
    const selectedPackageAmount = selectedPackage?.package_price;
    if (value !== NaN) {
      setFormData({
        ...formData,
        packageid: value,
        packageamount: selectedPackageAmount === undefined ? "" : selectedPackageAmount,
        retainerpaymentamount: selectedPackageAmount === undefined ? "" : selectedPackageAmount,
        sch2paymentamount: 0,
        sch3paymentamount: 0,
      });
    } else {
      setFormData({
        ...formData,
        packageid: "",
        retainerpaymentamount: 0,
        sch2paymentamount: 0,
        sch3paymentamount: 0,
      });
    }
  };

  const packageName = packageList?.data?.packages.find((item) => item?.id === parseInt(formData.packageid));

  const handleSetRetainerAmount = (e) => {
    const inputValue = e.target.value;
    const value = parseInt(e.target.value);
    const pkgValue = parseInt(formData.packageamount);
    const retainerValue = parseInt(formData.retainerpaymentamount);
    const sch2payment = parseInt(formData.sch2paymentamount);
    const sch3value = retainerValue + sch2payment;
    console.log(inputValue === "");
    if (inputValue === "") {
      setFormData({
        ...formData,
        retainerpaymentamount: "",
        sch2paymentamount: pkgValue,
        sch3paymentamount: 0,
      });
      return;
    }
    if (value >= pkgValue) {
      setFormData({
        ...formData,
        retainerpaymentamount: pkgValue,
        sch2paymentamount: 0,
        sch3paymentamount: 0,
      });
    } else if (value < pkgValue) {
      const schValue = pkgValue - value;
      setFormData({
        ...formData,
        retainerpaymentamount: value,
        sch2paymentamount: schValue,
        sch3paymentamount: 0,
      });
    } else if (pkgValue === sch3value) {
      setFormData({ ...formData, sch3paymentamount: 0 });
    }
  };
  // scheduler-2 Date and Amount selection
  const handleSetSchData = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      schpayment2: date, // Update only the eventdate property
    }));
  };
  const handleSetSchAmount = (e) => {
    const inputValue = e.target.value;
    const value = parseInt(e.target.value);
    const pkgValue = parseInt(formData.packageamount);
    const retainerValue = parseInt(formData.retainerpaymentamount);

    let sch3paymentamount = 0;
    const schLastAmount = value + retainerValue;
    const schAmount = pkgValue - retainerValue;

    if (inputValue === "") {
      setFormData({
        ...formData,
        retainerpaymentamount: pkgValue,
        sch2paymentamount: "",
        sch3paymentamount: 0,
      });
      return;
    }

    if (pkgValue === schLastAmount) {
      setFormData({ ...formData, sch3paymentamount: 0, sch2paymentamount: value });
      return;
    } else if (pkgValue > schLastAmount) {
      sch3paymentamount = pkgValue - schLastAmount;
      setFormData({ ...formData, sch2paymentamount: value, sch3paymentamount: sch3paymentamount });
    } else if (pkgValue < schLastAmount) {
      setFormData({
        ...formData,
        sch2paymentamount: schAmount,
        sch3paymentamount: 0,
      });
    }
  };
  // scheduler-3 Date and Amount selection
  const handleSetSchLastData = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      schpayment3: date,
    }));
  };
  const handleSetSchLastAmount = (e) => {
    const value = e.target.value;
  };
  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(":");
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${adjustedHours}:${minutes} ${period}`;
  };
  const [flippedIndex, setFlippedIndex] = useState(null);

  const handleFlip = (index) => {
    setFlippedIndex((prev) => (prev === index ? null : index));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (musictimeoutRef.current && !musictimeoutRef.current.contains(event.target)) {
        setShowMusicSelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputClick = (inputType) => {
    if (inputType === "music") {
      setShowMusicSelect(true);
    }
  };
  const musicTimeoutIdRef = useRef(null);

  const handleMusicChange = (e) => {
    const value = e.target.value;
    setMusicSelected(value);
    if (musicTimeoutIdRef.current) {
      clearTimeout(musicTimeoutIdRef.current);
    }
    musicTimeoutIdRef.current = setTimeout(() => {
      setMusic(value);
    }, 1000);
  };

  const handleMusicSelect = (name) => {
    const isAlreadySelected = musicDataTitle.some((music) => music?.id === name?.id);
    if (!isAlreadySelected) {
      setMusicDataTitle((prevMusic) => [...prevMusic, { id: name.id, title: name.title }]);
    }
    setMusicSelected(name?.title);
    setShowMusicSelect(false);
  };

  const handleRemoveMusic = (item) => {
    setMusicDataTitle((prevMusic) => prevMusic.filter((music) => music.id !== item.id));
  };

  const handleServiceData = (selectedOptions) => {
    setFormData((prevData) => {
      // If `selectedOptions` is empty or undefined, clear `servicesarr`
      const updatedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];

      return {
        ...prevData,
        servicesarr: updatedValues, // Replace with the updated selections
      };
    });
  };
  const handleCalenderToForm = (name) => {
    const startDate = new Date(`${name?.start_date}`);
    const startTime = new Date(`${name?.start_date}T${name?.start_time}`);
    const endTime = new Date(`${name?.start_date}T${name?.end_time}`);
    setFormData({
      ...formData,
      eventdate: startDate,
      starttime: startTime,
      endtime: endTime,
    });
  };

  const blockNumbers = (val) => {};
  return (
    <Content>
      <Block size="lg">
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h5" className="page-title">
             
             { book?.[activeVariable]?.section6?.map((item) => item?.text1)}
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <form onSubmit={addEventId ? handleSubmit(eventEditSumbmit) : handleSubmit(eventSubmit)}>
          <Card className="border-1 p-3">
            <Nav tabs className="mx-3">
              <NavItem>
                <NavLink
                  // disabled
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeIconTab === "1" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <Icon name="layers" /> <span>Event Details</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  // disabled
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeIconTab === "2" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("2");
                  }}
                >
                  <Icon name="music" /> <span>
                   
                    { book?.[activeVariable]?.section6?.map((item) => item?.text2)}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  // disabled
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeIconTab === "3" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("3");
                  }}
                >
                  <Icon name="sign-dollar" /> <span>Financial Status</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeIconTab}>
              <TabPane tabId="1">
                <PreviewCard className="border-0">
                  <Row className="gy-4">
                    <Col lg="6" className="border p-4 mt-0">
                      <Row className="my-2">
                        <Col sm="6">
                          <div className="form-group">
                            <Label htmlFor="default-0" className="form-label">
                              Event Name <Required />
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                // required
                                className="form-control"
                                type="text"
                                id="eventname"
                                name="eventname"
                                placeholder="Enter Event Name"
                                {...register("eventname", {
                                  required: "This field is required",
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: "Only alphabets and spaces are allowed",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "Please enter at least  characters",
                                  },
                                })}
                                value={formData.eventname}
                                onChange={(e) => {
                                  setFormData({ ...formData, eventname: e.target.value });
                                  clearErrors(e.target.name);
                                }}
                                maxLength={30}
                              />
                              {errors.eventname && <span className="invalid">{errors.eventname.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              Event Type <Required />
                            </Label>
                            <div className="form-control-wrap">
                              <select
                                name="eventType"
                                id="eventType"
                                className="form-select"
                                {...register("eventType", { required: "This field is required" })}
                                value={formData.eventType}
                                onChange={(e) => handleEventType(e)}
                              >
                                <option value="" className=" p-0 m-0 my-1 p-1 rounded-0">
                                  Select Event Type
                                </option>
                                {categoryListLoading ? (
                                  <div className="mx-1 align-center">
                                    <Spinner size="sm" color="light" />
                                    <span className="px-1">Loading...</span>
                                  </div>
                                ) : categoryListError ? (
                                  <div>Error loading type. Please try again.</div>
                                ) : (
                                  categoryList?.data?.Category &&
                                  categoryList?.data?.Category.map((item, idx) => (
                                    <option key={idx} value={item.id} className=" p-0 m-0 my-1 p-1 rounded-0">
                                      {item?.title}
                                    </option>
                                  ))
                                )}
                              </select>
                              {errors.eventType && <span className="invalid">{errors.eventType.message}</span>}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col sm="6">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              Attire <Required />
                            </Label>
                            <div className="form-control-wrap">
                              <select
                                name="attire"
                                id="attire"
                                className="form-select"
                                {...register("attire", { required: "This field is required" })}
                                value={formData.attire}
                                onChange={(e) => handleAttire(e)}
                              >
                                <option value="" className=" p-0 m-0 my-1 p-1 rounded-0">
                                  Select attire
                                </option>
                                {attireListLoading ? (
                                  <div className="mx-1 align-center">
                                    <Spinner size="sm" color="light" />
                                    <span className="px-1">Loading...</span>
                                  </div>
                                ) : attireListError ? (
                                  <div>Error loading attire. Please try again.</div>
                                ) : (
                                  attireList?.data?.packages &&
                                  attireList?.data?.packages.map((item, idx) => (
                                    <option key={idx} value={item.id} className=" p-0 m-0 my-1 p-1 rounded-0">
                                      {item?.title}
                                    </option>
                                  ))
                                )}
                              </select>
                              {errors.attire && <span className="invalid">{errors.attire.message}</span>}
                            </div>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="form-group">
                            <Label htmlFor="g_count" className="form-label">
                              Guest Count <Required />
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="number"
                                id="g_count"
                                placeholder="Enter Guest Count"
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                {...register("guestcount", {
                                  required: "This field is required",
                                  maxLength: {
                                    value: 15,
                                    message: "You rich maximum guest count limit!",
                                  },
                                })}
                                value={formData.guestcount}
                                onChange={(e) => {
                                  if (e.target.value.length <= 5) {
                                    setFormData({ ...formData, guestcount: e.target.value });
                                  } else {
                                    blockNumbers();
                                  }
                                  clearErrors(e.target.name);
                                }}
                              />
                              {errors.guestcount && <span className="invalid">{errors.guestcount.message}</span>}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="6" className="border p-4 mt-0">
                      <Row className="my-2">
                        <Col sm="6">
                          <div className="form-group">
                            <Label htmlFor="default-0" className="form-label">
                              Event Date <Required />
                            </Label>
                            <div className="form-control-wrap">
                              <DatePicker
                                autoComplete="off"
                                required
                                placeholderText="Enter Event Date"
                                isClearable
                                selected={formData.eventdate ? new Date(formData.eventdate) : null}
                                onChange={(date) => {
                                  handleSetEventDate(date);
                                }}
                                dateFormat="dd/MM/yy"
                                className="form-control date-picker"
                                minDate={tomorrow}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormatCalendar="MMMM"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              Setup Time
                            </Label>
                            <div className="form-control-wrap">
                              <DatePicker
                                autoComplete="off"
                                minTime={
                                  formData.starttime
                                    ? new Date(new Date(formData.starttime).getTime() - 15 * 60 * 1000)
                                    : new Date().setHours(0, 0, 0, 0)
                                }
                                maxTime={
                                  formData.starttime
                                    ? new Date(new Date(formData.starttime).getTime())
                                    : new Date().setHours(23, 59, 59, 999)
                                }
                                placeholderText="Enter Setup Time"
                                selected={formData.setuptime ? new Date(formData.setuptime) : null}
                                onChange={(time) => {
                                  if (time == null) {
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      setuptime: "",
                                    }));
                                  } else {
                                    const adjustedTime = adjustTimeWithStartDate(time, formData.eventdate);
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      setuptime: adjustedTime,
                                    }));
                                  }
                                }}
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className="form-control date-picker"
                                showTimeSelect
                                showTimeSelectOnly
                                isClearable={eventDateSelected}
                                disabled={!eventDateSelected}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col sm="6">
                          <div className="form-group">
                            <Label htmlFor="default-0" className="form-label">
                              Start Time <Required />
                            </Label>
                            <div className="form-control-wrap">
                              <DatePicker
                                autoComplete="off"
                                required
                                minTime={
                                  formData.setuptime
                                    ? new Date(new Date(formData.setuptime).getTime() + 15 * 60 * 1000)
                                    : new Date().setHours(0, 0, 0, 0)
                                }
                                maxTime={new Date().setHours(23, 59, 59, 999)}
                                placeholderText="Select Start Time"
                                selected={formData.starttime ? new Date(formData.starttime) : null}
                                onChange={(time) => {
                                  if (time == null || time === "") {
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      starttime: "",
                                    }));
                                  } else {
                                    const adjustedTime = adjustTimeWithStartDate(time, formData.eventdate);
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      starttime: adjustedTime,
                                    }));
                                  }
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className="form-control date-picker"
                                isClearable={eventDateSelected}
                                disabled={!eventDateSelected}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              End Time <Required />
                            </Label>
                            <div className="form-control-wrap">
                              <DatePicker
                                autoComplete="off"
                                required
                                minTime={
                                  formData.starttime
                                    ? new Date(new Date(formData.starttime).getTime() + 15 * 60 * 1000)
                                    : new Date().setHours(0, 0, 0, 0)
                                }
                                maxTime={new Date().setHours(23, 59, 59, 999)}
                                placeholderText="Select End Time"
                                selected={formData.endtime ? new Date(formData.endtime) : null}
                                onChange={(time) => {
                                  if (time == null) {
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      endtime: "",
                                    }));
                                  } else {
                                    const adjustedTime = adjustTimeWithStartDate(time, formData.eventdate);
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      endtime: adjustedTime,
                                    }));
                                  }
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className="form-control date-picker"
                                isClearable={eventDateSelected}
                                disabled={!eventDateSelected}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="6" className="border px-4 mt-0">
                      <div className="form-group my-4 ">
                        <Label htmlFor="default-1" className="form-label">
                          Selected Venue <Required />
                        </Label>
                        <div className="form-control-wrap w-50">
                          <div className="form-control-select">
                            <select
                              name="eventvenue"
                              id="eventvenue"
                              className="form-select"
                              {...register("eventvenue", { required: "This field is required" })}
                              value={formData.eventvenue}
                              onChange={(e) => {
                                handleVenues(e);
                                clearErrors(e.target.name);
                              }}
                            >
                              <option value="" className=" p-0 m-0 my-1 p-1 rounded-0">
                                Select venue
                              </option>
                              {venueListLoading ? (
                                <div className="mx-1 align-center">
                                  <Spinner size="sm" color="light" />
                                  <span className="px-1">Loading...</span>
                                </div>
                              ) : venueListError ? (
                                <div>Error loading venues. Please try again.</div>
                              ) : (
                                venueList?.data?.list &&
                                venueList?.data?.list.map((item, idx) => (
                                  <option key={idx} value={item.id} className=" p-0 m-0 my-1 p-1 rounded-0">
                                    {item?.venue_name}
                                  </option>
                                ))
                              )}
                            </select>
                            {errors.eventvenue && <span className="invalid">{errors.eventvenue.message}</span>}
                          </div>
                        </div>
                      </div>
                      <Card className="my-4 shadow-sm">
                        <CardBody>
                          <Form>
                            <Label htmlFor="terms-checkbox" className="form-label">
                              Terms & Conditions <Required />
                            </Label>
                            <div className="d-flex align-items-start mt-2">
                              <input
                                type="checkbox"
                                name="checkbox4"
                                id="terms-checkbox"
                                className="me-2 mt-1"
                                onClick={handleTerms}
                                {...register("checkbox4", { required: "This field is required" })}
                              />

                              <p className="mb-0">
                                Agree to our{" "}
                                <Link to="/terms-and-conditions">
                               
                    { book?.[activeVariable]?.section6?.map((item) => item?.text3)}
                                </Link>
                                . Please check our{" "}
                                <Link to="/privacy-policy">
                                
                    { book?.[activeVariable]?.section6?.map((item) => item?.text11)}
                                </Link>,{" "}
                                <Link to="/refund-and-cancellation">
                              
                    { book?.[activeVariable]?.section6?.map((item) => item?.text12)}
                                </Link>{" "}
                                before you confirm.
                              </p>
                            </div>

                            {errors.checkbox4 && (
                              <span className="text-danger small fst-italic">{errors.checkbox4.message}</span>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6" className="border px-4 mt-0">
                      <div>
                        <div className="d-flex align-center justify-between mt-2">
                          <p className="">
                         
                    { book?.[activeVariable]?.section6?.map((item) => item?.text4)}
                            </p>
                          <button class="BtnCalendarUI" color="primary" onClick={toggleTop}>
                            <div class="signCalendarUI">
                              <Icon name="calendar" className="text-white"></Icon>
                            </div>

                            <div class="textCalendarUI">Visit Calendar</div>
                          </button>
                        </div>
                        {isCalendarVisible && <calendar />}
                      </div>

                      <Modal isOpen={modalTop} toggle={toggleTop} size="xl" style={{ height: 600 }}>
                        <ModalHeader
                          toggle={toggleTop}
                          close={
                            <button className="close ml-auto" onClick={toggleTop}>
                              <Icon name="cross" />
                            </button>
                          }
                        >
                   
                    { book?.[activeVariable]?.section6?.map((item) => item?.text5)}
                        </ModalHeader>

                        <ModalBody style={{ height: "600px" }}>
                          <CalenderApp
                            onsubmit={onsubmit}
                            setFormDataSlot={setFormDataSlot}
                            formDataSlot={formDataSlot}
                            modalTop={modalTop}
                            setModalTop={setModalTop}
                          />
                        </ModalBody>
                      </Modal>

                      <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className="p-0 m-0 justify-center"
                        containerClass=""
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1440,
                            },
                            items: 4,
                            partialVisibilityGutter: 50,
                          },
                          mobile: {
                            breakpoint: {
                              max: 600,
                              min: 0,
                            },
                            items: 1,
                            partialVisibilityGutter: 30,
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 600,
                            },
                            items: 2,
                            partialVisibilityGutter: 40,
                          },
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                      >
                        {slotListLoading ? (
                          <div className="mx-1 align-center">
                            <Spinner size="sm" color="light" />
                            <span className="px-1">Loading...</span>
                          </div>
                        ) : slotListtError ? (
                          <div>Error loading slot. Please try again.</div>
                        ) : slotList?.data ? (
                          slotList.data
                            // .filter((name) => name?.start_date > formatDates(new Date())) // Filter true data here
                            .filter((name) => new Date(name?.start_date) >= new Date(formatDates(new Date())))
                            .map((name, index) => (
                              <div
                                className="mx-2"
                                key={index}
                                onClick={() => {
                                  handleFlip(index); // Assuming this function handles card flipping
                                  handleCalenderToForm(name);
                                  // Set the event date and times
                                  setEventdate(new Date(name?.start_date)); // Convert start_date to Date object
                                  setStart(new Date(`1970-01-01T${name?.start_time}`)); // Ensure start_time is parsed correctly
                                  setEnd(new Date(`1970-01-01T${name?.end_time}`)); // Ensure end_time is parsed correctly
                                }}
                              >
                                <div className="availabelslotUI text-center bg-transparent w-100 p-1 h-130px">
                                  <div className="availabel-slot-innerUI">
                                    {/* Front Side */}
                                    <div className="availabel-slot-frontUI border border-primary rounded-3 shadow-sm h-120px">
                                      <p className="fw-bold mt-1">
                                        {name.byweekday === "su"
                                          ? "Sunday"
                                          : name.byweekday === "sa"
                                          ? "Saturday"
                                          : name.byweekday === "mo"
                                          ? "Monday"
                                          : name.byweekday === "tu"
                                          ? "Tuesday"
                                          : name.byweekday === "we"
                                          ? "Wednesday"
                                          : name.byweekday === "th"
                                          ? "Thursday"
                                          : name.byweekday === "fr"
                                          ? "Friday"
                                          : ""}
                                      </p>
                                      <p>
                                        {name?.bymonthday}{" "}
                                        {name.bymonth === "1"
                                          ? "Jan"
                                          : name.bymonth === "2"
                                          ? "Feb"
                                          : name.bymonth === "3"
                                          ? "Mar"
                                          : name.bymonth === "4"
                                          ? "Apr"
                                          : name.bymonth === "5"
                                          ? "May"
                                          : name.bymonth === "6"
                                          ? "Jun"
                                          : name.bymonth === "7"
                                          ? "Jul"
                                          : name.bymonth === "8"
                                          ? "Aug"
                                          : name.bymonth === "9"
                                          ? "Sep"
                                          : name.bymonth === "10"
                                          ? "Oct"
                                          : name.bymonth === "11"
                                          ? "Nov"
                                          : name.bymonth === "12"
                                          ? "Dec"
                                          : ""}
                                      </p>
                                      <div
                                        className="fw-bold bg-custom p-0 m-3"
                                        style={{
                                          backgroundColor: "#60CF4F",
                                          fontSize: "10px",
                                          color: "black",
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertTo12HourFormat(name.start_time)} -{" "}
                                        {convertTo12HourFormat(name.end_time)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <div className="text-center mt-1">
                            <img src={NoFounds} height={59} width={50} alt="Image not found" />
                          </div>
                        )}
                      </Carousel>
                    </Col>
                  </Row>
                </PreviewCard>
              </TabPane>
              <TabPane tabId="2">
                <Row className="g-0">
                  <Col lg={6} className="px-1 border border-2 p-3 px-3">
                    <div>
                      <Label>
                  
                    { book?.[activeVariable]?.section6?.map((item) => item?.text6)}
                        </Label>
                      <RSelect
                        options={
                          categoryListLoading
                            ? []
                            : categoryListError
                            ? []
                            : categoryList?.data?.Category.map((item) => ({
                                value: item?.id,
                                label: item?.title,
                              })) || []
                        }
                        placeholder={
                          categoryListLoading ? (
                            <>
                              <Spinner size="sm" /> Loading categories...
                            </>
                          ) : categoryListError ? (
                            "No category list available"
                          ) : (
                            "Select categories"
                          )
                        }
                        onChange={(selectedOptions) => handleServiceData(selectedOptions)}
                        isMulti
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="p-3 px-3 border border-2">
                    <div className="h-100">
                      <Row>
                        <Col lg={5}>
                          {" "}
                          <Label>
                            {/* {book?.CRM?.section6.map((item) => item.text7)} */}
                          
                    { book?.[activeVariable]?.section6?.map((item) => item?.text7)}
                            </Label>
                          <Input
                            style={{ cursor: "pointer" }}
                            type="text"
                            value={musicSelected}
                            onClick={() => handleInputClick("music")}
                            onChange={handleMusicChange}
                            className="flex-grow-1 rounded-3 px-2 fs-6"
                            placeholder={ book?.[activeVariable]?.section9?.map((item) => item?.text1)}
                            
                          />
                          {showMusicSelect && (
                            <div
                              className="position-absolute h-200px overflow-auto heromanual-dropdown"
                              style={{ zIndex: 1, width: "18%" }}
                              ref={musictimeoutRef}
                            >
                              <DropdownMenu className="d-flex flex-wrap p-1 border w-100 top-0">
                                {musicListLoading ? (
                                  <div className="mx-1 align-center">
                                    <Spinner size="sm" color="light" />
                                    <span className="px-1">Loading...</span>
                                  </div>
                                ) : musicListError ? (
                                  <div>Error loading music. Please try again.</div>
                                ) : musicList?.data?.musics ? (
                                  musicList?.data?.musics.map((name, index) => (
                                    <div
                                      key={name?.id || index}
                                      className={`dropdown-item `}
                                      style={
                                        musicDataTitle.some((item) => item?.id === name?.id)
                                          ? { backgroundColor: "#e6eef5", cursor: "pointer", color: "#4fbb26" }
                                          : { cursor: "pointer" }
                                      }
                                      onClick={() => handleMusicSelect(name)}
                                    >
                                      {name?.title}
                                    </div>
                                  ))
                                ) : (
                                  <div>
                                    {/* {book?.CRM?.section6.map((item) => item.text8)} */}
                                   
                    { book?.[activeVariable]?.section6?.map((item) => item?.text8)}
                                    </div>
                                )}
                              </DropdownMenu>
                            </div>
                          )}
                        </Col>
                        <Col lg={7}>
                          <div className="flex justify-between">
                            <Label>
                          
                    { book?.[activeVariable]?.section6?.map((item) => item?.text9)}
                              </Label>
                            {musicDataTitle.length === 0}
                            <div className={musicDataTitle.length === 0 ? "d-none" : ""}>
                              <p>
                                Selected list count :{" "}
                                <span
                                  className="border border-primary text-primary p-1 px-2"
                                  style={{ borderRadius: "50%" }}
                                >
                                  {musicDataTitle.length}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="h-100px border border-1 overflow-auto">
                            {musicDataTitle.length === 0 ? (
                              <div className="h-100 justify-center align-center leaflet-bar">
                                
                    { book?.[activeVariable]?.section6?.map((item) => item?.text10)}
                              </div>
                            ) : (
                              musicDataTitle.map((item) => (
                                <Badge
                                  color="primary"
                                  className="rounded-4 text-white me-1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleRemoveMusic(item)}
                                >
                                  {item?.title}
                                </Badge>
                              ))
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                {/* </PreviewCard> */}
              </TabPane>
              <TabPane tabId="3">
                <PreviewCard className="border-0">
                  <Row className="gy-4">
                    <Col lg="6" className="border p-4 mt-0">
                      <Row className="my-2">
                        <Col sm="8">
                          <div className="form-group">
                            <Label htmlFor="default-0" className="form-label">
                              Package Name <Required />
                            </Label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <select
                                  name="packageid"
                                  id="packageid"
                                  className="form-select"
                                  {...(activeIconTab === "3"
                                    ? register("packageid", { required: "This field is required" })
                                    : {})}
                                  value={formData.packageid}
                                  onChange={(e) => {
                                    handlePackagePrice(e);
                                    clearErrors(e.target.name);
                                  }}
                                >
                                  {" "}
                                  <option value="" className=" p-0 m-0 my-1 p-1 rounded-0">
                                    Select Package
                                  </option>
                                  {packageListLoading ? (
                                    <div className="mx-1 align-center">
                                      <Spinner size="sm" color="light" />
                                      <span className="px-1">Loading...</span>
                                    </div>
                                  ) : packageListtError ? (
                                    <div>Error loading package. Please try again.</div>
                                  ) : (
                                    packageList?.data?.packages &&
                                    packageList?.data?.packages.map((item, idx) => (
                                      <option key={idx} value={item?.id} className=" p-0 m-0 my-1 p-1 rounded-0">
                                        {item?.package_name}
                                      </option>
                                    ))
                                  )}
                                </select>
                                {errors.packageid && (
                                  <div className="ff-italic fs-11px text-danger">{errors.packageid.message}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              Selected Package Amount
                            </Label>
                            <div className="form-control-wrap">
                              <div className="input-group">
                                <input
                                  className="form-control fw-normal"
                                  type="text"
                                  id="default-0"
                                  placeholder="0"
                                  disabled
                                  value={formData.packageamount}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text" id="basic-addon2">
                                    $
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-2 mt-3">
                        <Col sm="8">
                          <div className="form-group align-center h-100">
                            <Label htmlFor="default-1" className="form-label mb-0">
                              The retainer amount depends on your package. You can adjust the retainer amount according
                              to the scheduler date amount provided below.
                            </Label>
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              Amount
                            </Label>
                            <div className="form-control-wrap">
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="retainer amount"
                                  value={formData.retainerpaymentamount}
                                  onChange={handleSetRetainerAmount}
                                  disabled={!formData.packageamount}
                                />

                                <div className="input-group-append">
                                  <span className="input-group-text" id="basic-addon2">
                                    $
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-2 mt-3">
                        <Col sm="8">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              Scheduled Payment - 2
                            </Label>
                            <div className="form-control-wrap">
                              <DatePicker
                                maxDate={
                                  formData.eventdate
                                    ? new Date(
                                        new Date(formData.eventdate).setDate(new Date(formData.eventdate).getDate() - 1)
                                      )
                                    : null
                                }
                                minDate={new Date().setDate(new Date().getDate() + 1)}
                                isClearable
                                selected={formData.schpayment2}
                                onChange={(date) => handleSetSchData(date)}
                                dateFormat={"dd/MM/yy"}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormatCalendar="MMMM"
                                className="form-control date-picker"
                                placeholderText="Enter Scheduler-2 Date"
                              />{" "}
                              {scheFirstErrorChk && (
                                <div className="ff-italic fs-11px text-danger">This field is required</div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              Amount
                            </Label>
                            <div className="form-control-wrap">
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Scheduled Payment - 2 amount"
                                  value={formData.sch2paymentamount}
                                  onChange={(e) => handleSetSchAmount(e)}
                                  disabled={!formData.sch2paymentamount}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text" id="basic-addon2">
                                    $
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-2 mt-3">
                        <Col sm="8">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              Scheduled Payment - 3
                            </Label>
                            <div className="form-control-wrap">
                              <DatePicker
                                maxDate={
                                  formData.eventdate
                                    ? new Date(
                                        new Date(formData.eventdate).setDate(new Date(formData.eventdate).getDate() - 1)
                                      )
                                    : null
                                }
                                minDate={
                                  formData.schpayment2
                                    ? new Date(
                                        new Date(formData.schpayment2).setDate(
                                          new Date(formData.schpayment2).getDate() + 1
                                        )
                                      ) // One day after Scheduler-2
                                    : new Date().setDate(new Date().getDate() + 1) // Default to one day after today if Scheduler-2 is not set
                                }
                                isClearable
                                selected={formData.schpayment3}
                                onChange={(date) => handleSetSchLastData(date)}
                                dateFormat={"dd/MM/yy"}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormatCalendar="MMMM"
                                className="form-control date-picker"
                                placeholderText="Enter Scheduler-3 Date"
                              />{" "}
                              {scheLastErrorChk && (
                                <div className="ff-italic fs-11px text-danger">This field is required</div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="form-group">
                            <Label htmlFor="default-1" className="form-label">
                              Amount
                            </Label>
                            <div className="form-control-wrap">
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="0"
                                  value={formData.sch3paymentamount}
                                  onChange={(e) => handleSetSchLastAmount(e)}
                                  disabled
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text" id="basic-addon2">
                                    $
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6" className="border p-4 mt-0">
                      <Card className="border-0 my-2">
                        <Label htmlFor="default-0" className="form-label">
                          Fee Summary
                        </Label>
                        <Block className="border-0 m-0">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{packageName?.package_name} Package</td>
                                {formData.packageamount && <td>{formData.packageamount}</td>}
                              </tr>
                              <tr>
                                <td>Retainer Amount</td>
                                <td>{formData.retainerpaymentamount}</td>
                              </tr>
                              <tr>
                                <td>Balance Due</td>
                                <td>
                                  {formData.sch2paymentamount && formData.sch3paymentamount
                                    ? parseInt(formData.sch2paymentamount) + parseInt(formData.sch3paymentamount)
                                    : formData.sch2paymentamount
                                    ? parseInt(formData.sch2paymentamount) + 0
                                    : 0}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Block>
                      </Card>
                    </Col>
                  </Row>
                </PreviewCard>
              </TabPane>
            </TabContent>
          </Card>
          <div
            className={activeIconTab === "1" ? "d-flex justify-end mb-2 mt-3" : "d-flex justify-between mb-2 mt-3"}
            type="submit"
          >
            {activeIconTab !== "1" && (
              <Button color="primary" onClick={() => setActiveIconTab(back.toString())} type="button">
                Previous
              </Button>
            )}
            {loading ? (
              <Button disabled color="primary">
                <Spinner size="sm" />
                <span> Loading... </span>
              </Button>
            ) : (
              <Button color="primary" type="submit">
                {activeIconTab === "3" ? "Save" : "Save & Next"}
              </Button>
            )}
          </div>
        </form>
      </Block>

      {/* <Modal size="md" isOpen={modalSmall} toggle={toggleSmall}>
        <ModalHeader
          className="justify-between"
          toggle={toggleSmall}
          close={
            <button className="close" onClick={toggleSmall}>
              <Icon name="cross" />
            </button>
          }
        >
          Terms & Conditions
        </ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <div>
              <input type="checkbox" className="mt-1" onClick={handleTerms} />
            </div>
            <p className="ms-1 mb-0">
              Agree to our <Link to="/terms-and-conditions">DJ booking terms</Link>. Please check our{" "}
              <Link to="/privacy-policy">policies</Link>,{" "}
              <Link to="/refund-and-cancellation">payments & cancellations</Link> before you confirm.
            </p>
          </div>
          <div className="justify-end mt-2">
            <Button color="primary" onClick={toggleSmall}>
              Acepted!
            </Button>
          </div>
        </ModalBody>
      </Modal> */}
    </Content>
  );
};

export default DJBookingEvent;
