import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import dj from "../../jsonfiles/Dj.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const TermsCondition = () => {

  const activeVariable = getActiveEnvironmentVariable();


  return (
    <Container className="mt-5">
      <Card>
        <Card.Body>
          <Card.Title>Terms and Conditions</Card.Title>
          <p>
            Welcome to <strong>
              {/* {dj?.CRM?.section5?.map((Item)=>Item?.title2)} */}
             
                  { dj?.[activeVariable]?.section5?.map((item) => item?.title2)}
              </strong>. By accessing or using our website, mobile app, or any other
            service (collectively, the "Platform"), you agree to comply with and be bound by the following Terms and
            Conditions ("T&Cs"). Please read these T&Cs carefully before using our Platform.
          </p>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>1.Introduction</strong>
              <p>These T&Cs apply to all users of the Platform, including but not limited to:</p>
              <ul>
                <li>
                  <b>- Clients:</b> 
                  
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title1)}
                </li>
                <li>
                  <b>
                    {/* {dj?.CRM?.section6?.map((Item)=>Item?.title4)} */}
                    { dj?.[activeVariable]?.section6?.map((item) => item?.title4)}
                    </b>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title2)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title2)}
                </li>
                <li>
                  <b>- Visitors :</b>(those merely browsing the Platform).
                </li>
              </ul>
              <p>
                By creating an account, making a booking, or using any of the services offered on the Platform, you
                acknowledge that you have read, understood, and agreed to be bound by these T&Cs.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>2. Definitions</strong>
              <ul>
                <li>
                  <b>- Platform:</b>
                   {/* {dj?.CRM?.section6?.map((Item)=>Item?.title3)} */}
                   { dj?.[activeVariable]?.section6?.map((item) => item?.title3)}
                </li>
                <li>
                  <b>
                    {/* {dj?.CRM?.section6?.map((Item)=>Item?.title4)} */}
                    { dj?.[activeVariable]?.section6?.map((item) => item?.title4)}
                    </b> 
                    {/* {dj?.CRM?.section6?.map((Item)=>Item?.title5)} */}
                    { dj?.[activeVariable]?.section6?.map((item) => item?.title5)}
                </li>
                <li>
                  <b>- Client:</b>
                   {/* {dj?.CRM?.section6?.map((Item)=>Item?.title6)} */}
                   { dj?.[activeVariable]?.section6?.map((item) => item?.title6)}
                </li>
                <li>
                  <b>- Booking:</b>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title7)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title7)}
                </li>
                <li>
                  <b>- Service Fee:</b>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title8)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title8)}
                </li>
              </ul>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>3. User Account Registration</strong>
              <ListGroup.Item>
                <strong>3.1. Eligibility</strong>
                <ul>
                  <li>Be at least 18 years old or of legal age in your jurisdiction,</li>
                  <li>Have the legal capacity to enter into a binding contract, and</li>
                  <li>Agree to abide by these T&Cs.</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>3.2. Account Creation</strong>
                <p>
                  When you register for an account, you agree to provide accurate and up-to-date information, including
                  your:
                </p>
                <ul>
                  <li>Full name,</li>
                  <li>Email address,</li>
                  <li>Phone number, and</li>
                  <li>Payment details.</li>
                </ul>
                <p>
                  You are responsible for maintaining the confidentiality of your login credentials and all activities
                  that occur under your account.
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>3.3. Account Termination</strong>
                <p>
                  We reserve the right to suspend or terminate your account at any time if we believe you have violated
                  these T&Cs or engaged in fraudulent, illegal, or inappropriate conduct.
                </p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>4. Booking Process</strong>
              <ListGroup.Item>
                <strong>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title9)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title9)}
                  </strong>
                <p>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title10)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title10)}
                  </p>
                <ul>
                  <li>Availability,</li>
                  <li>Pricing,</li>
                  <li>Performance types,</li>
                  <li>Equipment provided, and</li>
                  <li>Reviews from previous clients.</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>4.2. Making a Booking</strong>
                <p>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title11)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title11)}
                  </p>
                <ol>
                  <li>
                    {/* {dj?.CRM?.section6?.map((Item)=>Item?.title12)} */}
                    { dj?.[activeVariable]?.section6?.map((item) => item?.title12)}
                    </li>
                  <li>Specify the event date, time, and location,</li>
                  <li>Provide any specific event details or requests, and</li>
                  <li>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title13)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title13)}
                  </li>
                </ol>
                <p>
                {/* {dj?.CRM?.section6?.map((Item)=>Item?.title14)} */}
                { dj?.[activeVariable]?.section6?.map((item) => item?.title14)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>4.3. Booking Changes</strong>
                <p>
                {/* {dj?.CRM?.section6?.map((Item)=>Item?.title15)} */}
                { dj?.[activeVariable]?.section6?.map((item) => item?.title15)}
                </p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>5. Payment Terms</strong>
              <ListGroup.Item>
                <strong>5.1. Payment Methods</strong>
                <p>The Platform accepts the following payment methods:</p>
                <ul>
                  <li>Credit/Debit Cards (Visa, Mastercard, etc.),</li>
                  <li>PayPal, and</li>
                  <li>Other locally accepted payment options (as applicable).</li>
                </ul>
                <p>
                  All payments are processed through secure, third-party payment gateways. We do not store payment card
                  information.
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>5.2. Service Fees</strong>
                <p>
                  The Platform charges a service fee for facilitating the booking. This fee is included in the total
                  booking price and will be clearly displayed at checkout. Clients agree to pay the full amount at the
                  time of booking.
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>5.3. Deposit and Final Payments</strong>
                <ul>
                  <li>
                    <b>Deposit:</b> 
                    {/* {dj?.CRM?.section6?.map((Item)=>Item?.title16)} */}
                    { dj?.[activeVariable]?.section6?.map((item) => item?.title16)}
                  </li>
                  <li>
                    <b>Final Payment:</b> The remaining balance, if any, must be paid 1 day prior to the event date,
                    unless otherwise stated.
                  </li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>5.4. Late Payments</strong>
                <p>
                  Failure to make payments on time may result in the cancellation of the booking and forfeiture of any
                  deposits paid.
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>5.5. Taxes</strong>
                <p>
                  The prices displayed on the Platform may be subject to applicable taxes based on your jurisdiction.
                  Any taxes will be clearly indicated at checkout.
                </p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>6. Cancellation and Refund Policy</strong>
              <ListGroup.Item>
                <strong>6.1. Client Cancellations</strong>
                <p>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title17)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title17)}
                  </p>
                <ul>
                  <li>
                    <b>Cancellations made 2 days before the event:</b> Full refund, less the non-refundable deposit (if
                    applicable).
                  </li>
                  <li>
                    <b>Cancellations made within 2 days of the event:</b> No refund, and full payment is due.
                  </li>
                </ul>
                <p>
                  Refunds will be processed according to the payment method used for the booking. Processing times may
                  vary depending on the payment provider.
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title18)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title18)}
                  </strong>
                <p>
                {/* {dj?.CRM?.section6?.map((Item)=>Item?.title19)} */}
                { dj?.[activeVariable]?.section6?.map((item) => item?.title19)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>6.3. Event Cancellations Due to Force Majeure</strong>
                <p>
                {/* {dj?.CRM?.section6?.map((Item)=>Item?.title20)} */}
                { dj?.[activeVariable]?.section6?.map((item) => item?.title20)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>6.4. Refund Disputes</strong>
                <p>
                  Any disputes regarding cancellations and refunds should be raised through the Platform’s customer
                  support team within 2 days of the event date. The Platform will review the case and make a final
                  decision.
                </p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>7. Responsibilities and Conduct</strong>
              <ListGroup.Item>
                <strong>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title21)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title21)}
                  </strong>
                <p>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title22)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title22)}
                  </p>
                <ul>
                  <li>Arriving at the event venue on time,</li>
                  <li>Bringing the necessary equipment,</li>
                  <li>Performing professionally, and</li>
                  <li>Adhering to the agreed-upon terms of the booking.</li>
                </ul>
                <p>
                {/* {dj?.CRM?.section6?.map((Item)=>Item?.title23)} */}
                { dj?.[activeVariable]?.section6?.map((item) => item?.title23)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>7.2. Client Responsibilities</strong>
                <p>Clients are responsible for:</p>
                <ul>
                  <li>Providing accurate event details (venue, timing, equipment needs),</li>
                  <li>
                    {/* {dj?.CRM?.section6?.map((Item)=>Item?.title24)} */}
                    { dj?.[activeVariable]?.section6?.map((item) => item?.title24)}
                    </li>
                  <li>Complying with local laws (e.g., sound permits or licenses), and</li>
                  <li>Ensuring payment is made in full before the event.</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>7.3. Prohibited Conduct</strong>
                <p>
                  {/* {dj?.CRM?.section6?.map((Item)=>Item?.title25)} */}
                  { dj?.[activeVariable]?.section6?.map((item) => item?.title25)}
                  </p>
                <ul>
                  <li>Use the Platform for illegal purposes,</li>
                  <li>Post false, defamatory, or misleading information,</li>
                  <li>
                    Discriminate against others based on race, gender, age, religion, or other protected
                    characteristics,
                  </li>
                  <li>Attempt to bypass the Platform to make off-platform bookings, or</li>
                  <li>Engage in any fraudulent activity.</li>
                </ul>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>8. Intellectual Property</strong>
              <ListGroup.Item>
                <strong>8.1. Ownership</strong>
                <p>
                  All content on the Platform, including logos, designs, text, graphics, and software, is the property
                  of the Platform or its licensors. Users are not allowed to copy, distribute, or modify content without
                  prior written consent.
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>8.2. User-Generated Content</strong>
                <p>
                  Users may submit reviews, event photos, or feedback through the Platform. By submitting content, you
                  grant the Platform a non-exclusive, royalty-free, perpetual license to use, reproduce, and modify the
                  content for marketing and operational purposes.
                </p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>9. Limitation of Liability</strong>
              <p>To the maximum extent permitted by law:</p>
              <ul>
                <li>
                  The Platform is provided on an “as-is” basis without warranties of any kind, either express or
                  implied.
                </li>
                <li>
                  The Platform is not liable for any indirect, incidental, or consequential damages arising out of or
                  related to the use of the Platform.
                </li>
                <li>
                  The Platform is not responsible for the actions or omissions of DJs or Clients, including event
                  performance, service quality, or payment disputes.
                </li>
              </ul>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>10. Dispute Resolution</strong>
              <ListGroup.Item>
                <strong>10.1. Governing Law</strong>
                <p>
                  These T&Cs are governed by the laws of India/Maharashtra, without regard to its conflict of law
                  principles.
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>10.2. Dispute Resolution Process</strong>
                <p>
                  Any disputes arising out of these T&Cs will first be resolved through good faith negotiations. If the
                  dispute cannot be resolved through negotiation, the parties agree to submit the dispute to binding
                  arbitration under the rules of It Act,2000.
                </p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>11. Changes to Terms</strong>
              <p>
                We reserve the right to update these T&Cs at any time. Any material changes will be posted on the
                Platform, and continued use of the Platform after the changes are made will constitute acceptance of the
                revised T&Cs.
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>12. Contact Information</strong>
              <p>For any questions regarding these Terms and Conditions, you can contact us at:</p>
              <strong>
                {/* {dj?.CRM?.section6?.map((Item)=>Item?.title26)} */}
                { dj?.[activeVariable]?.section6?.map((item) => item?.title26)}
                </strong>
              <p className="mb-0">
                <b>Email:</b>
                {/* {dj?.CRM?.section6?.map((Item)=>Item?.title27)} */}
                { dj?.[activeVariable]?.section6?.map((item) => item?.title27)}
              </p>
              <p className="mb-0">
                <b>Phone:</b> +91 9156362425
              </p>
              <p className="mb-0">
                <b>Address:</b> Office No 204, Eden Garden Commercial Complex Near Khanvilkar Petrol Pump, Nagala Park,
                Kolhapur 416003 Maharashtra, India.
              </p>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TermsCondition;
