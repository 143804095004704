import React, { useEffect, useState } from "react";
import { Row, Col } from "../../../components/Component";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import DatePicker from "react-datepicker";
import { Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NoFounds from "../../../images/No Result.png";
import Cookies from "js-cookie";
import { getAllCompanies } from "../../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import { useTheme } from "../../../layout/provider/Theme";
import { getAvailableSlot } from "../../../http/get/getApi";
import { getAvailableSlot1 } from "../../../http/get/getApi";
import { FaBook } from "react-icons/fa";
import book from "../../../jsonfiles/Booking.json";
// import profileimage from ""
import { Links } from "../../../jsonfiles/Links";
import { getActiveEnvironmentVariable } from "../../../utils/Utils";

const timeData = [{ start_time: "12:00:00", end_time: "24:00:00" }];

const startTime = new Date();
const endTime = new Date();

// const EventView = (event) => {
//   const [mouseEnter, setMouseEnter] = useState(false);
//   const { title, extendedProps, publicId } = event.event.event._def;
//   console.log(title, extendedProps, publicId);
//   return (
//     <React.Fragment>
//       <div id={publicId} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
//         {title}
//       </div>{" "}
//       {/* <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
//         <PopoverHeader>{title}</PopoverHeader>
//         <PopoverBody>{extendedProps.description}</PopoverBody>
//       </Popover> */}
//     </React.Fragment>
//   );
// };
const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div id={publicId} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
        {title}
      </div>{" "}
     
    </React.Fragment>
  );
};



const CalenderApp = ({ events1, events, onEdit, setModal1, setFormDataSlot, formDataSlot, modalTop, setModalTop }) => {
  const [modalState, updateModal] = useState(false);
  const [mockEvents, updateEvents] = useState(events1);
  const [event, updateEvent] = useState({});
  const [theme, settheme] = useState();
  const [edit, updateEditModal] = useState(false);
  const [eventid, setEventId] = useState();

  
  const activeVariable = getActiveEnvironmentVariable();

  const [slotTransormData, setSlotTransformData] = useState([]);

  const [dates, setDates] = useState({
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });

  const naviget = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams();
  const { key } = useParams();

  useEffect(() => {
    updateEvents(events);
  }, [events]);

  const { skin } = useTheme();

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "white",
      border: "1px solid #384d69",
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      border: "1px solid #384d69",
    }),
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = (formData) => {
    let newEvent = {};
    newEvent = {
      id: event.id,
      key: event.key,
      className: theme.value,
      type: theme,
      title: formData.title,
      start: event.start,
      end: event.end,
      description: formData.description,
    };
    onEdit(newEvent);
    settheme("");
    toggleEdit();
  };

  const toggle = () => {
    updateModal(!modalState);
  };

  const toggleEdit = () => {
    updateEditModal(!edit);
  };

  const [showcalendar, setShowcalendar] = useState(true);

  function getEventIdNumeric(eventId) {
    const parts = eventId?.split("-");
    return parts.pop();
  }

  // function handleEventClick(info) {
  //   console.log("info", info);
  //   const eventId = info.event.id;
  //   const eventKey = info.event.key;
  //   const numericId = getEventIdNumeric(eventId);
  //   setEventId(numericId);
  //   deletetoggleModal();
  // }

  const handleEventClick = (info) => {
    setShowcalendar(false);
    const event = mockEvents?.find((item) => item?.id === info?.event?._def?.publicId);
    const fullId = info?.event?._def?.publicId;
    const eventIdS = fullId?.replace(/\D/g, ""); // This will give "101"
    const eventId = Number(eventIdS);

    setFormData((prevData) => ({
      ...prevData, // Spread the previous form data
      id: eventId, // Set the id as eventId
    }));
    setEventId(eventId);
    // const event1 = slotTransormData?.find((item) => item.id === eventid);
    const event1 = slotTransormData?.find((item) => item?.id === eventId);
    setSelectedDate(event1.start_date);
    setFormDataSlot({
      ...formDataSlot,

      eventDate: event1.start_date,
      startTime: null,
      endTime: null,
    });

    setFormData((prevState) => ({
      ...prevState,
      startdate: new Date(event1?.start_date),
      enddate: new Date(event1?.end_date),
      From: new Date(`1970-01-01T${event1?.start_time}`),
      to: new Date(`1970-01-01T${event1?.end_time}`),
      interval: event1?.interval,
      occur: event1?.recurring,
      // days:["Mon", "Tue", "Wed"]
      days: event1?.days ? event1?.days : [],
    }));

    updateEvent(event);
    settheme(event?.type);
    if (event?.id?.includes("default-event-id")) {
      toggle();
      // } else if (event1?.id) {
    } else {
      deletetoggleModal();
    }
    // deletetoggleModal();
    // setModal()
  };

  const {
    data: availableSlots,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["get-available-slot", id],
    queryFn: () => getAvailableSlot1({ id }), // Call your API function
    staleTime: Infinity,
  });


  const profileimage =  Links?.[activeVariable]?.Home?.[0]?.url4;
    console.log(profileimage)

  useEffect(() => {
    const transformedAvailableSlots = Array.isArray(availableSlots?.data)
      ? availableSlots?.data?.map((slot) => ({
          id: slot.id,
          start_date: slot.start_date,
          start_time: slot.start_time,
          end_date: slot.end_date,
          end_time: slot.end_time,
          recurring: slot.recurring,
          interval: slot.interval,
          days: slot.days,
        }))
      : [];
    if (transformedAvailableSlots.length > 0) {
      setSlotTransformData(transformedAvailableSlots);
    }
  }, [availableSlots]);

  const handleDelete = (eventid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // deleteavlSlot(eventid);
      }
      deletetoggleModal();
    });
  };

  useEffect(() => {
    reset(event);
  }, [event]);

  function editEvent() {
    naviget(`add-event?type=edit&id=${10}&from=fromEdit`);
    setModal1(false);
  }
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const toggleModal = () => setModal(!modal);
  const deletetoggleModal = () => setDeleteModal(!deleteModal);
  const [formData, setFormData] = useState({
    startdate: "",
    enddate: "",
    From: "",
    to: "",
    occur: "",
    interval: "",
    byweekday: "",
    bymonthday: "",
    bymonth: ",",
    startTime: null,
    endTime: null,
  });

  const handleback = () => {
    setShowcalendar(true);
    // If unchecked, clear startTime and endTime
    setFormDataSlot({
      ...formDataSlot,
      startTime: null,
      endTime: null,
    });
  };

  const {
    data: companyData,
    isLoading: companyDataLoading,
    isError: companyDataError,
  } = useQuery({
    queryKey: ["get-company-by-id", id],
    queryFn: () => getAllCompanies({ id: id }),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  // const [modalTop, setModalTop] = useState(false);
  const toggleTop = () => setModalTop(!modalTop);

  const cookieValue = Cookies.get("authToken");
  let currentUrl;
  const showSignMessege = () => {
    const { eventDate, startTime, endTime } = formDataSlot;

    if (!cookieValue) {
      Swal.fire({
        title: "You are not Sign-In",
        text:book?.[activeVariable]?.section10?.map((item) => item?.text1),
          // (showIpdm && book?.CRM?.section10?.map((item) => item?.text1)) ||
          // (showPhotography && book?.photography?.section10?.map((item) => item?.text1)) ||
          // (showConstruction && book?.construction?.section10?.map((item) => item?.text1)),
        imageUrl: NoFounds,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Sign-In Image",
        confirmButtonText: "Sign-In",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1-day expiration for the cookie
          Cookies.set("cookieVal", "clientRole3", {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/${id}`, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          window.location = process.env.REACT_APP_ACCOUNT_URL;
        }
      });
    } else if (!formDataSlot.startTime || !formDataSlot.endTime) {
      if (availableSlots1?.data.length === 0) {
        Swal.fire({
          title: "There is no slot available",
          text: "Please choose the another date.",
          icon: "warning",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        Swal.fire({
          title: "Incomplete Booking Information",
          text: "Please choose the slots or select both a start time and an end time.",
          icon: "warning",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      return;
    } else {
      // Close the modal first if it is open
      if (modalTop) {
        toggleTop(); // Close the modal
        console.log("Modal closed.");
      }

      if (window.location.pathname === `/dj-profiles/specificDj/${id}/dj-bookevent`) {
        return naviget(`/dj-profiles/specificDj/${id}/dj-bookevent`, {
          state: { eventDate, startTime, endTime, companyData },
          replace: true, // This will update the state without reloading or navigating
        });
      } else {
        return naviget(`/dj-profiles/specificDj/${id}/dj-bookevent`, {
          state: { eventDate, startTime, endTime, companyData },
        });
      }
    }
  };

  const handleDateClick = (info) => {
    setShowcalendar(false);
    setFormDataSlot({
      ...formDataSlot,

      eventDate: info.dateStr,
      startTime: null,
      endTime: null,
    });
    setSelectedDate(info.dateStr);
    setFormData({ startdate: info.dateStr });
    const selectedDate1 = new Date(info.date);
    const clickedDay = selectedDate1.toLocaleDateString("en-US", { weekday: "short" });

    // const clickedDay = selectedDate1.toISOString().split("T")[0];
    const day = clickedDay.toLowerCase().slice(0, 2);

    setFormData((prevState) => ({
      ...prevState,
      enddate: info.dateStr,
    }));

    setFormData((prevState) => ({
      ...prevState,
      byweekday: day,
    }));
    const monthday = selectedDate1.getDate();
    setFormData((prevState) => ({
      ...prevState,
      bymonthday: monthday,
    }));
    const month = selectedDate1.getMonth() + 1;
    setFormData((prevState) => ({
      ...prevState,
      bymonth: month,
    }));

    toggleModal();
  };

  const data = [
    {
      id: "default-event-id-" + 1,
      title: "Annual Event",
      rrule: {
        freq: "yearly", // yearly recurrence
        interval: 1, // every year
        bymonth: [8], // August (Month 8)
        bymonthday: [15], // 15th day of August
        byweekday: null, // null because it’s not restricted to specific weekdays
        dtstart: "2024-08-15", // start date
        until: null, // end date (optional, if you want to specify an end date)
      },
      allDay: true, // Event spans the whole day
      color: "green", // different color for the annual event
    },
    {
      id: "default-event-id-" + 2,
      title: "Annual Event",
      rrule: {
        freq: "yearly", // yearly recurrence
        interval: 1, // every year
        bymonth: [9], // August (Month 8)
        bymonthday: [15], // 15th day of August
        byweekday: null, // null because it’s not restricted to specific weekdays
        dtstart: "2024-09-15", // start date
        until: null, // end date (optional, if you want to specify an end date)
      },
      allDay: true, // Event spans the whole day
      color: "green", // different color for the annual event
    },
  ];

  // console.log("availableSlots", availableSlots);/
  const [availableTimes, setAvailableTimes] = useState([]);

  // Helper function to generate time intervals between start and end times
  const generateTimeSlots = (startTime, endTime, interval = 5) => {
    const timeSlots = [];
    let currentTime = new Date(startTime);

    while (currentTime <= endTime) {
      timeSlots.push(new Date(currentTime)); // Store the current time slot
      currentTime = new Date(currentTime.getTime() + interval * 60 * 1000); // Increment by interval (5 mins)
    }

    return timeSlots;
  };

  function convertTo12HourFormat(time24) {
    const [hour, minute] = time24.split(":");
    let hourNum = parseInt(hour, 10);
    const ampm = hourNum >= 12 ? "PM" : "AM";
    hourNum = hourNum % 12 || 12;
    return `${hourNum}:${minute.padStart(2, "0")} ${ampm}`;
  }

  const rruleFormatData = availableSlots?.data?.map((data) => {
    const startTime12 = convertTo12HourFormat(data.start_time);
    const endTime12 = convertTo12HourFormat(data.end_time);
    const sday = new Date(data.start_date).getDate();
    const eday = new Date(data.end_date).getDate();
    const recurring = data.recurring === null || undefined ? "daily" : data.recurring?.toLowerCase();

    return {
      id: "default-slot-id-" + data.id,
      title: `${startTime12} to ${endTime12}`,
      rrule: {
        freq: recurring,
        interval: data.interval ? JSON.parse(data.interval) : 1, // default interval if missing
        byweekday: recurring === "daily" || recurring === "monthly" || recurring === "yearly" ? null : [data.byweekday],
        bymonthday:
          recurring === "yearly" || (recurring === "monthly" && sday <= eday)
            ? Array.from({ length: eday - sday + 1 }, (_, i) => i + sday) // Create array from sday to eday
            : data.bymonthday && recurring !== "weekly" && recurring !== "daily"
            ? [parseInt(data.bymonthday, 10)]
            : null,
        bymonth: recurring === "yearly" ? [parseInt(data.bymonth, 10)] : null,
        dtstart: data.start_date,
        until: recurring === "yearly" ? null : data.end_date || null,
      },
      color: "green",
      type: "slot",
    };
  });

  const totalData = [...(rruleFormatData || [])];
  // Initially set to 'all' to show all slots
  const [selectedSlots, setSelectedSlots] = useState({
    all: true, // Initially all checkboxes are unchecked except 'all'
    morning: false,
    afternoon: false,
    night: false,
  });

  const [filter, setFilter] = useState(["all"]);

  // Your existing code for rendering the slots remains unchanged
  const handleCheckboxChange = (e) => {
    const { name } = e.target;

    setSelectedSlots((prev) => {
      const updatedSlots = {
        morning: false,
        afternoon: false,
        night: false,
        all: false,
      };

      // Set the selected time period to true
      if (name === "all") {
        updatedSlots.all = true;
      } else {
        updatedSlots[name] = true;
      }

      return updatedSlots; // Return the updated slots state
    });
  };

  const formatTime = (date) => {
    return date ? date.toLocaleString([], { hour: "2-digit", minute: "2-digit", hour12: true }) : "";
  };

  const [interval1, setInterval1] = useState(60);

  const {
    data: availableSlots1,
    isLoading: availableSlotLoading,
    isError: availableSlotError,
  } = useQuery({
    queryKey: ["get-available-slot", id, selectedDate, interval1],
    queryFn: () =>
      getAvailableSlot({
        id,
        date: selectedDate,
        interval1,
      }),
    staleTime: Infinity,
  });
  const getFilteredSlots = (selectedSlots, availableSlots) => {
    if (selectedSlots.all) {
      return availableSlots?.data || [];
    }
    let combinedSlots = [];
    if (selectedSlots.morning) {
      combinedSlots = availableSlots?.data.filter((slot) => slot.start >= "06:00:00" && slot.start < "12:00:00");
    } else if (selectedSlots.afternoon) {
      combinedSlots = availableSlots?.data.filter((slot) => slot.start >= "12:00:00" && slot.start < "18:00:00");
    } else if (selectedSlots.night) {
      combinedSlots = availableSlots?.data.filter((slot) => slot.start >= "18:00:00" && slot.start < "23:00:00");
    }
    // return combinedSlots.length > 0 ? combinedSlots : [];
    return (combinedSlots?.length || 0) > 0 ? combinedSlots : [];
  };
  const setTimeForToday = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number); // Extract time parts
    const today = new Date(); // Get the current date
    today.setHours(hours, minutes, seconds, 0); // Set the time for today

    return today;
  };
  useEffect(() => {
    const buttons = document.querySelectorAll(
      ".fc-timeGridWeek-button, .fc-timeGridDay-button, .fc-listWeek-button, .fc-dayGridMonth-button"
    );

    buttons.forEach((button) => {
      button.addEventListener("click", function () {
        buttons.forEach((btn) => btn.classList.remove("active"));

        this.classList.add("active");
      });
    });

    const defaultButton = document.querySelector(".fc-dayGridMonth-button");
    if (defaultButton) {
      defaultButton.classList.add("active");
    }

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", function () {});
      });
    };
  }, []);

  // console.log("eventDate", formDataSlot.eventDate);
  // console.log("startTime", formDataSlot.startTime);
  // console.log("endTime", formDataSlot.endTime);

  return (
    <React.Fragment>
      {showcalendar ? (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin, interactionPlugin, rrulePlugin]}
          events={totalData}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          initialView="customMonth"
          dayMaxEventRows={2}
          headerToolbar={{
            left: "title prev,next",
            center: null,
            right: "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          themeSystem="bootstrap5"
          height={560}
          contentHeight={780}
          // eventContent={(e) => <EventView event={e} />}
          aspectRatio={3}
          editable={true}
          droppable={true}
          views={{
            customMonth: {
              type: "dayGridMonth",
              fixedWeekCount: false,
            },
          }}
          validRange={{
            start: new Date(),
          }}
        />
      ) : (
        <>
          {availableSlotLoading ? (
            <Row>
              <Col lg={8}>
                <div className="p-5">
                  <ShimmerThumbnail height={700} width={750} />
                </div>
              </Col>
              <Col lg={4}>
                <div className="p-5">
                  <ShimmerThumbnail height={600} width={330} />
                </div>
              </Col>
            </Row>
          ) : (
            <div className="row h-100">
              <div className="col-lg-9 mt-2">
                <div className="card---container d-flex flex-row align-items-center justify-content-center">
                  <label htmlFor="allCheckbox" className="card--- d-flex align-items-center justify-content-center">
                    <div className="card---cover d-flex align-items-center fs-4 form-check">
                      <input
                        type="radio"
                        className="form-check-input me-2"
                        name="all"
                        id="allCheckbox"
                        checked={selectedSlots.all}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="allCheckbox" className="card---title form-check-label mt-1">
                        All
                      </label>
                    </div>
                  </label>
                  <label htmlFor="morningCheckbox" className="card--- d-flex align-items-center justify-content-center">
                    <div className="card---cover d-flex align-items-center fs-4 form-check">
                      <input
                        type="radio"
                        className="form-check-input me-2"
                        name="morning"
                        id="morningCheckbox"
                        checked={selectedSlots.morning}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="morningCheckbox" className="card---title form-check-label mt-1">
                        Morning
                      </label>
                    </div>
                  </label>

                  <label
                    htmlFor="afternoonCheckbox"
                    className="card--- d-flex align-items-center justify-content-center"
                  >
                    <div className="card---cover d-flex align-items-center fs-4 form-check">
                      <input
                        type="radio"
                        className="form-check-input me-2"
                        name="afternoon"
                        id="afternoonCheckbox"
                        checked={selectedSlots.afternoon}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="afternoonCheckbox" className="card---title form-check-label mt-1">
                        Afternoon
                      </label>
                    </div>
                  </label>

                  <label htmlFor="nightCheckbox" className="card--- d-flex align-items-center justify-content-center">
                    <div className="card---cover d-flex align-items-center fs-4 form-check">
                      <input
                        type="radio"
                        className="form-check-input me-2"
                        name="night"
                        id="nightCheckbox"
                        checked={selectedSlots.night}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="nightCheckbox" className="card---title form-check-label mt-1">
                        Night
                      </label>
                    </div>
                  </label>

                  {/* <label htmlFor="allCheckbox" className="card--- d-flex align-items-center justify-content-center">
                  <div className="card---cover d-flex align-items-center fs-4 form-check">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name="all"
                      id="allCheckbox"
                      checked={selectedSlots.all}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="allCheckbox" className="card---title form-check-label mt-1">
                      All
                    </label>
                  </div>
                </label> */}
                </div>
                <div className="d-flex flex-column h-100">
                  {/* Start Time Picker */}
                  <div className="w-100 mt-2">
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                      <div className="form-control-wrap has-timepicker flex-grow-1 mb-3 mx-1">
                        <label>Start Time</label>
                        <DatePicker
                          disabled={availableSlots1?.data.length === 0}
                          minTime={
                            availableSlots1?.startAndEndTime?.[0]?.start_time
                              ? setTimeForToday(availableSlots1.startAndEndTime[0].start_time)
                              : new Date().setHours(0, 0, 0, 0) // fallback to midnight if no starttime
                          }
                          maxTime={
                            availableSlots1?.startAndEndTime?.[0]?.end_time
                              ? setTimeForToday(availableSlots1.startAndEndTime[0].end_time)
                              : new Date().setHours(23, 59, 59, 999) // fallback to end of the day if no endtime
                          }
                          autoComplete="off"
                          selected={formDataSlot.startTime}
                          onChange={(date) => setFormDataSlot({ ...formDataSlot, startTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select start time"
                          className="form-control date-picker"
                        />
                      </div>
                      <div className="form-control-wrap has-timepicker flex-grow-1 mx-1">
                        <label>End Time</label>
                        <DatePicker
                          minTime={
                            formDataSlot.startTime
                              ? new Date(new Date(formDataSlot.startTime).getTime() + 15 * 60 * 1000) // 15 minutes before starttime
                              : new Date().setHours(0, 0, 0, 0) // fallback to midnight if no starttime
                          }
                          maxTime={
                            availableSlots1?.startAndEndTime?.[0]?.end_time
                              ? setTimeForToday(availableSlots1.startAndEndTime[0].end_time)
                              : new Date().setHours(23, 59, 59, 999) // fallback to end of the day if no endtime
                          }
                          selected={formDataSlot.endTime}
                          onChange={(date) => setFormDataSlot({ ...formDataSlot, endTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select end time"
                          className="form-control date-picker"
                          disabled={!formDataSlot.startTime}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="h-100 mt-md-0 mt-3  fs-3 text-center">
                    {getFilteredSlots(selectedSlots, availableSlots1).length > 0 ? (
                      <label className="">Available Slot</label>
                    ) : (
                      ""
                    )}
                    <div className="badge---card mt-3">
                      {availableSlots1?.data && (
                        <div
                          className="slider-container justify-content-center w-100"
                          style={{ height: "300px", overflowY: "auto" }}
                        >
                          <div className="badge---card border-bottom pb-4 mt-3 d-flex flex-row align-items-center justify-content-center w-100">
                            {getFilteredSlots(selectedSlots, availableSlots1).length > 0 ? (
                              getFilteredSlots(selectedSlots, availableSlots1).map((slot, index) => (
                                <div key={index} className="mx-2 justify-content-center">
                                  <label>
                                    <Badge pill color="light" className="card---badge">
                                      <input
                                        type="radio"
                                        className="me-2"
                                        style={{ cursor: "pointer" }}
                                        name="timeSlot" // Ensure radio buttons are grouped by name
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setFormDataSlot({
                                              ...formDataSlot,
                                              startTime: new Date(`1970-01-01T${slot.start}`),
                                              endTime: new Date(`1970-01-01T${slot.end}`),
                                            });
                                          }
                                        }}
                                      />
                                      <div className="fs-6">
                                        {slot?.start?.slice(0, 5)} to {slot?.end?.slice(0, 5)}
                                      </div>
                                    </Badge>
                                  </label>
                                </div>
                              ))
                            ) : (
                              <div className="mb-2 w-100 w-md-75 mx-auto ">
                                {selectedSlots.morning && <div className="mb-2">No morning slots available.</div>}
                                {selectedSlots.afternoon && <div className="mb-2">No afternoon slots available.</div>}
                                {selectedSlots.night && <div className="mb-2">No night slots available.</div>}
                                {selectedSlots.all && (
                                  <div className="text-center justify-content-center mb-2">No slots available.</div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-0">
                <div className="leaflet-bar shadow border border-primary h-100">
                  <img
                    alt="profile-image"
                    src={availableSlots1?.company_profile || profileimage}
                    className="card---img card-img-top img-fluid object-fit-cover"
                  />
                  <div className="container">
                    {selectedDate && (
                      <div className="mt-3 ">
                        <span className="text-muted  fs-6">Event Date: </span>
                        <strong className="text-muted d-inline fs-6">
                          {new Date(selectedDate).toISOString().split("T")[0]}
                        </strong>
                      </div>
                    )}
                    {formDataSlot.startTime && (
                      <div className="mt-2">
                        <span className="text-muted me-2 fs-6"> Start Time:</span>
                        <strong className="text-muted fs-6">{formatTime(formDataSlot.startTime)}</strong>
                      </div>
                    )}
                    {formDataSlot.endTime && (
                      <div className="mt-2">
                        <p>
                          <span className="text-muted me-2 fs-6">End Time: </span>{" "}
                          <span className="text-muted fs-6">{formatTime(formDataSlot.endTime)}</span>
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="justify-center mt-2">
                    <div className="d-flex">
                      <Button color="danger" onClick={handleback}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
                        Back
                      </Button>

                      <Button color="primary" className="ms-3" onClick={showSignMessege}>
                        Book Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default CalenderApp;
