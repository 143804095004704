import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown, Spinner } from "reactstrap";
import { Icon, TooltipComponent } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { getCookie, getInitiatals } from "../../../../utils/Utils";
import Cookies from "js-cookie";
import img1 from "../../../../images/Dj.jpg";
import { getClientData } from "../../../../http/get/getApi";
import { useQuery } from "react-query";
import dj from "../../../../jsonfiles/Dj.json";
import { getActiveEnvironmentVariable } from "../../../../utils/Utils";

const User = () => {

  const activeVariable = getActiveEnvironmentVariable();

  // based on environment variables
  const showIpdm = process.env.REACT_APP_DISPLAY_IPDM === "true";
  const showPhotography = process.env.REACT_APP_DISPLAY_PHOTOGRAPHY === "true";
  const showConstruction = process.env.REACT_APP_DISPLAY_CONSTRUCTION === "true";

  const [profileName, setProfileName] = useState(getCookie("username", "John"));
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };
  const setLogout = () => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("logoutURL", process.env.REACT_APP_PUBLIC_URL, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };
  const chkDJcompany = Cookies.get("role");

  const {
    data: clientData,
    isLoading: clientDataLoading,
    isError: clientDataError,
  } = useQuery({
    queryKey: ["get-client-id"],
    queryFn: () => getClientData(),
    keepPreviousData: true,
    staleTime: Infinity,
  });
  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div style={{ width: "40px", height: "40px" }}>
          {clientDataLoading ? (
            <div className="" style={{ height: "25px" }}>
              <Spinner style={{ height: "40px", width: "40px" }} color="light" />
              <div className="top-0 position-absolute z-n1 h-75 w-75">
                <UserAvatar icon="user-alt" theme="primary" />
              </div>
            </div>
          ) : clientDataError ? (
            <div className="h-75 w-75" id="user">
              <UserAvatar icon="alert-circle" theme="danger" className="fs-3" />
              <TooltipComponent id="user" text={"Something went wrong!!!"} direction="left" />
            </div>
          ) : clientData ? (
            <img src={clientData} alt="dj" className="h-100 object-fit-cover" style={{ borderRadius: "50%" }} />
          ) : (
            <UserAvatar text={getInitiatals(profileName)} theme="primary" className="fs-7 h-100 w-100" />
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter ">
          <div className="user-card sm">
            <div className="user-info px-2">
              {clientData ? (
                <div style={{ width: "50px", height: "50px" }}>
                  <img src={clientData} alt={ dj?.[activeVariable]?.section8?.map((item) => item?.alt1)}></img>
                  {/* {showIpdm && dj?.CRM?.section8?.map((item)=>item?.alt1) || showPhotography && dj?.photography?.section8?.map((item)=>item?.alt1) || showConstruction && dj?.construction?.section8?.map((item)=>item?.alt1) } className="h-100 object-fit-cover" style={{ borderRadius: "50%" }} /> */}
                </div>
              ) : (
                <UserAvatar text={getInitiatals(profileName)} theme="primary" />
              )}
            </div>
            <div className="user-info">
              <span className="lead-text" id="user">
                {getCookie("username") && getCookie("username").length > 20
                  ? `${getCookie("username").substring(0, 20)}...`
                  : getCookie("username") || "John"}
                <TooltipComponent id="user" text={getCookie("username")} direction="left" />
              </span>
              <span className="sub-text" id="emaildata">
                {getCookie("email") && getCookie("email").length > 25
                  ? `${getCookie("email").substring(0, 25)}...`
                  : getCookie("email") || "john@ipartydjmix.com"}
                <TooltipComponent id="emaildata" text={getCookie("email")} direction="left" />
              </span>
            </div>
          </div>
        </div>
        {chkDJcompany === "3" ? (
          <div className="dropdown-inner">
            <LinkList>
              <LinkItem
                link={`${process.env.REACT_APP_CLIENT_URL}`}
                icon="dashboard"
                target="_blank"
                onClick={() => toggle()}
              >
               
                { dj?.[activeVariable]?.section2?.map((item) => item?.title1)}
              </LinkItem>
            </LinkList>
          </div>
        ) : chkDJcompany == "2" ? (
          <div className="dropdown-inner">
            <LinkList>
              <LinkItem
                link={`${process.env.REACT_APP_DASHBOARD_URL}Company-profile`}
                icon="dashboard"
                target="_blank"
                onClick={() => toggle()}
              >
            
                { dj?.[activeVariable]?.section2?.map((item) => item?.title2)}
              </LinkItem>
            </LinkList>
          </div>
        ) : (
          ""
        )}
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="#" icon="signout" onClick={setLogout}>
              {/* Sign Out */}
          
                { dj?.[activeVariable]?.section2?.map((item) => item?.title3)}
            </LinkItem>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
