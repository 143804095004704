import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import dj from "../../jsonfiles/Dj.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const TermsCondition = () => {

  const activeVariable = getActiveEnvironmentVariable();

 

  return (
    <Container className="mt-5">
      <Card>
        <Card.Body>
          <Card.Title>Refund & Cancellation Policy</Card.Title>
          <p>
            At <strong>
               
               { dj?.[activeVariable]?.section5?.map((item) => item?.title2)}

              </strong>
              {/* {dj?.CRM?.section5?.map((Item)=>Item?.title3)} */}
              { dj?.[activeVariable]?.section5?.map((item) => item?.title3)}
          </p>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>1. General Overview</strong>
              <p>
              {/* {dj?.CRM?.section5?.map((Item)=>Item?.title4)} */}
              { dj?.[activeVariable]?.section5?.map((item) => item?.title4)}
                <br />
                {/* {dj?.CRM?.section5?.map((Item)=>Item?.title5)} */}
                { dj?.[activeVariable]?.section5?.map((item) => item?.title5)}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>2. Cancellation Policy</strong>
              <p>
                <strong>2.1. User-Initiated Cancellations</strong>
                <br />
                {/* {dj?.CRM?.section5?.map((Item)=>Item?.title6)} */}
                { dj?.[activeVariable]?.section5?.map((item) => item?.title6)}
                {" "}
                <strong>"My Bookings"</strong> section.
              </p>
              <p>
                <strong>a. Free Cancellation Window</strong>
                <br />
                {/* {dj?.CRM?.section5?.map((Item)=>Item?.title7)} */}
                { dj?.[activeVariable]?.section5?.map((item) => item?.title7)}
              </p>
              <p>
                <strong>b. Late Cancellations</strong>
                <br />
                If cancelled after the free cancellation window but 6 hours before the event, a cancellation fee of 50%
                of booking amount will apply. The remaining balance will be refunded to the user’s original payment
                method.
              </p>
              <p>
                <strong>c. Last-Minute Cancellations</strong>
                <br />
                {/* {dj?.CRM?.section5?.map((Item)=>Item?.title8)} */}
                { dj?.[activeVariable]?.section5?.map((item) => item?.title8)}
              </p>

              <p>
                <strong>
                  {/* {dj?.CRM?.section5?.map((Item)=>Item?.title9)} */}
                  { dj?.[activeVariable]?.section5?.map((item) => item?.title9)}
                  </strong>
                <br />
                {/* {dj?.CRM?.section5?.map((Item)=>Item?.title10)} */}
                { dj?.[activeVariable]?.section5?.map((item) => item?.title10)}
                <ul>
                  <li>
                    <strong>Full Refund</strong>: Users will receive a full refund of all amounts paid.
                  </li>
                  <li>
                    <strong>Assistance with Rebooking</strong>
                    {/* {dj?.CRM?.section5?.map((Item)=>Item?.title11)} */}
                    { dj?.[activeVariable]?.section5?.map((item) => item?.title11)}
                  </li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>3. Refund Policy</strong>
              <p>
                <strong>3.1. Refund Eligibility</strong>
                <br />
                Refunds will be processed according to the following criteria:
                <ul>
                  <li>
                    <strong>Within the Free Cancellation Window</strong>: If a user cancels within the specified free
                    cancellation period, a full refund will be provided with no deductions.
                  </li>
                  <li>
                    <strong>Late Cancellations</strong>: A partial refund (minus the cancellation fee) will be provided
                    if the cancellation is made after the free cancellation period but within the eligible window for
                    partial refunds.
                  </li>
                  <li>
                    <strong>No Refunds for Last-Minute Cancellations</strong>: No refunds will be issued for
                    cancellations made within 1 hour of the event date, as the full booking amount will be charged.
                  </li>
                </ul>
              </p>
              <p>
                <strong>3.2. Refund Processing Time</strong>
                <br />
                Refunds will be processed back to the original payment method within 7 business days of the cancellation
                being confirmed. Depending on the user’s payment provider, it may take additional time for the refund to
                appear in their account.
              </p>
              <p>
                <strong>3.3. Non-Refundable Fees</strong>
                <br />
                Certain fees associated with bookings are non-refundable, including but not limited to:
                <ul>
                  <li>
                    <strong>Booking service fees</strong>: A platform fee charged at the time of booking may be
                    non-refundable, depending on the specific booking.
                  </li>
                  <li>
                    <strong>Transaction fees</strong>: Any third-party transaction fees (e.g., PayPal, credit card
                    processing) may also be non-refundable depending on the payment provider’s policies.
                  </li>
                </ul>
              </p>
              <p>
                <strong>3.4. Refund Processing Time:</strong>
                <br />
                After cancellation, the refund turnaround time should be clearly stated. For instance, refunds might be
                processed within 7-10 business days after the cancellation has been confirmed.
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>4. Event Rescheduling</strong>
              <p>
                <strong>4.1. User-Initiated Rescheduling</strong>
                <br />
                {/* {dj?.CRM?.section5?.map((Item)=>Item?.title12)} */}
                { dj?.[activeVariable]?.section5?.map((item) => item?.title12)}
                <ul>
                  <li>
                    <strong>No Rescheduling Fees</strong>: If the rescheduling is requested and approved within the
                    allowable period, no fees will apply.
                  </li>
                  <li>
                    <strong>Rescheduling Fees</strong>
                    {/* {dj?.CRM?.section5?.map((Item)=>Item?.title21)} */}
                    { dj?.[activeVariable]?.section5?.map((item) => item?.title21)}
                  </li>
                </ul>
              </p>
              <p>
                <strong>
                  {/* {dj?.CRM?.section5?.map((Item)=>Item?.title13)} */}
                  { dj?.[activeVariable]?.section5?.map((item) => item?.title13)}
                  </strong>
                <br />
                {/* {dj?.CRM?.section5?.map((Item)=>Item?.title14)} */}
                { dj?.[activeVariable]?.section5?.map((item) => item?.title14)}
                <ul>
                  <li>
                    <strong>User Options</strong>: Users can either accept the new date or cancel the booking for a full
                    refund.
                  </li>
                  <li>
                    <strong>No Additional Fees</strong>
                    {/* {dj?.CRM?.section5?.map((Item)=>Item?.title15)} */}
                    { dj?.[activeVariable]?.section5?.map((item) => item?.title15)}
                  </li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>5. Force Majeure</strong>
              <p>
              {/* {dj?.CRM?.section5?.map((Item)=>Item?.title16)} */}
              { dj?.[activeVariable]?.section5?.map((item) => item?.title16)}
                <ul>
                  <li>
                    <strong>Rescheduling</strong>: The event may be rescheduled for a later date without incurring any
                    additional charges.
                  </li>
                  <li>
                    <strong>Refunds</strong>: If the event is canceled and rescheduling is not possible, users will be
                    eligible for a refund of any amounts paid, minus any non-refundable fees.
                  </li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>6. No-Show Policy</strong>
              <p>
                If a user fails to appear for the event without prior cancellation or rescheduling, this will be treated
                as a <strong>no-show</strong>, and the following conditions apply:
                <ul>
                  <li>
                    <strong>No Refund</strong>: No refunds will be issued for no-shows, and the full booking amount will
                    be charged.
                  </li>
                  <li>
                    <strong>Rebooking</strong>: If users wish to rebook the service, they will need to make a new
                    booking at the current rates.
                  </li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>7. Changes to the Policy</strong>
              <p>
              {/* {dj?.CRM?.section5?.map((Item)=>Item?.title17)} */}
              { dj?.[activeVariable]?.section5?.map((item) => item?.title17)}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>8. How to Request a Cancellation or Refund</strong>
              <p>
                To request a cancellation, rescheduling, or refund, users can:
                <ul>
                  <li>
                  {/* {dj?.CRM?.section5?.map((Item)=>Item?.title18)}  */}
                  { dj?.[activeVariable]?.section5?.map((item) => item?.title18)}
                  <strong>"My Bookings"</strong>{" "}
                    section.
                  </li>
                  <li>Contact our customer support team at support@ipartydjmix.in or +91 9156362425.</li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>9. Contact Information</strong>
              <p>
                For any questions or concerns regarding this Refund & Cancellation Policy, please contact us at:
                <br />
                <strong>
                  {/* {dj?.CRM?.section5?.map((Item)=>Item?.title19)} */}
                  { dj?.[activeVariable]?.section5?.map((item) => item?.title19)}
                  </strong>
                <br />
                Email: support@ipartydjmix.in
                <br />
                Phone: +91 9156362425
                <br />
                Address: Office No 204, Eden Garden Commercial Complex Near Khanvilkar Petrol Pump, Nagala Park,
                Kolhapur 416003 Maharashtra, India.
              </p>
            </ListGroup.Item>
          </ListGroup>
          <p className="mt-4">
          {/* {dj?.CRM?.section5?.map((Item)=>Item?.title20)} */}
          { dj?.[activeVariable]?.section5?.map((item) => item?.title20)}
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TermsCondition;
