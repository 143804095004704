import React, { useEffect, useState } from "react";
import { Block } from "../../../components/Component";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../../css/style.css";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Button, Col, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Links } from "../../../jsonfiles/Links";
import bussiness from "../../../jsonfiles/BusinessPortal.json";
import Select, { components } from "react-select";
import { getActiveEnvironmentVariable } from "../../../utils/Utils";

const Required = () => {
  return (
    <>
      <span style={{ color: "red" }}> *</span>
    </>
  );
};
// const Option = (props) => (
//   <components.Option {...props} className="country-option">
//     <img src={props.data.icon} alt="logo" className="country-logo-business-portal-logo" />
//     {props.data.label}
//   </components.Option>
// );

const BusinessPortal = () => {
  const activeVariable = getActiveEnvironmentVariable();

  // based on environment variables
  const showIpdm = process.env.REACT_APP_DISPLAY_IPDM === "true";
  const showPhotography = process.env.REACT_APP_DISPLAY_PHOTOGRAPHY === "true";
  const showConstruction = process.env.REACT_APP_DISPLAY_CONSTRUCTION === "true";

  const img1 = Links?.[activeVariable]?.BusinessPortal?.[0]?.url1;
  // (showIpdm && Links?.BusinessPortalCRM[0].url) ||
  // (showPhotography && Links?.BusinessPortalPHOTO[0].url) ||
  // (showConstruction && Links?.BusinessPortalCONSTUCTION[0].url);
  const img2 = Links?.[activeVariable]?.BusinessPortal?.[2]?.url3;
  // (showIpdm && Links?.BusinessPortalCRM[1].url) ||
  // (showPhotography && Links?.BusinessPortalPHOTO[1].url) ||
  // (showConstruction && Links?.BusinessPortalCONSTUCTION[1].url);
  const img3 = Links?.[activeVariable]?.BusinessPortal?.[1]?.url2;
  // (showIpdm && Links?.BusinessPortalCRM[2].url) ||
  // (showPhotography && Links?.BusinessPortalPHOTO[2].url) ||
  // (showConstruction && Links?.BusinessPortalCONSTUCTION[2].url);
  const img4 = Links?.[activeVariable]?.BusinessPortal?.[3]?.url4;
  // (showIpdm && Links?.BusinessPortalCRM[3].url) ||
  // (showPhotography && Links?.BusinessPortalPHOTO[3].url) ||
  // (showConstruction && Links?.BusinessPortalCONSTUCTION[3].url);
  const img5 = Links?.[activeVariable]?.BusinessPortal?.[4]?.url5;
  // (showIpdm && Links?.BusinessPortalCRM[4].url) ||
  // (showPhotography && Links?.BusinessPortalPHOTO[4].url) ||
  // (showIpdm && Links?.BusinessPortalCONSTUCTION[4].url);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [formActive, setFormActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    country_id: "",
    message: "",
  });
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const handleContact = (value) => {
    setFormActive(value);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      country_id: "",
      message: "",
    });
    reset({});
  };
  const handleSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Information Send Successfully",
      text: "We will reach you soon!",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  // contact number operation
  // const [countries, setCountries] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState(null);
  // const handleChange = (value) => {
  //   setSelectedCountry(value);
  //   setFormData({ ...formData, country_id: value?.id });
  // };
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const countriesData = data.data.countries
  //         .filter((country) => country.id === 101 || country.id === 231)
  //         .map((country) => ({
  //           value: country.shortname,
  //           label: "+" + country.phonecode,
  //           id: country.id,
  //           icon: `https://flagsapi.com/${country.shortname}/shiny/64.png`,
  //         }));
  //       console.log(countriesData);
  //       setCountries(countriesData);
  //       setSelectedCountry(countriesData[0]);
  //     })
  //     .catch((error) => console.error("Error fetching countries:", error));
  //   }, []);
  //   useEffect(() => {
  //     setFormData({ ...formData, country_id: countries?.[0]?.id });
  //   }, [countries]);
  //   console.log(countries)
  //   const SingleValue = ({ children, ...props }) => (
  //   <components.SingleValue {...props}>
  //     <img src={props.data.icon} alt="s-logo" className="selected-logo-business-portal" />
  //     {children}
  //   </components.SingleValue>
  // );

  const renderForm = () => {
    return (
      <div className="h-100 w-100 m-2">
        <form onSubmit={handleSubmit(FormSubmit)}>
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <div className="form-control-wrap">
                  <label htmlFor="event_message" className="fs-6">
                    Full Name <Required />
                  </label>
                  <input
                    className="form-control border-0 text-white"
                    style={{ backgroundColor: "#00000047" }}
                    type="text"
                    id="name"
                    placeholder="Enter Full Name"
                    {...register("name", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "Only alphabets and spaces are allowed",
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter at least 3 characters",
                      },
                    })}
                    maxLength={30}
                    value={formData.name}
                    onChange={(e) => {
                      setFormData({ ...formData, name: e.target.value });
                      clearErrors(e.target.name);
                    }}
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group w-100">
                <div className="form-control-wrap">
                  <label htmlFor="event_message" className="fs-6">
                    Contact Number <Required />
                  </label>
                  {/* <div className="d-flex"> */}
                  <input
                    className="form-control border-0 text-white"
                    style={{ backgroundColor: "#00000047" }}
                    type="number"
                    id="phone_number"
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    placeholder="Enter Contact Number"
                    {...register("phone_number", {
                      required: "This is required",
                    })}
                    minLength={9}
                    value={formData.phone_number}
                    onChange={(e) => {
                      if (e.target.value.length <= 15) {
                        setFormData({ ...formData, phone_number: e.target.value });
                      } else {
                        blockNumbers();
                      }
                      clearErrors(e.target.value);
                    }}
                  />
                  {/* </div> */}
                  {errors.phone_number && <span className="invalid">{errors.phone_number.message}</span>}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <div className="form-control-wrap mt-1">
                  <label htmlFor="event_message" className="fs-6">
                    Email
                  </label>
                  <input
                    className="form-control border-0 text-white"
                    style={{ backgroundColor: "#00000047" }}
                    type="text"
                    id="email"
                    placeholder="Enter Email"
                    {...register("email", {
                      pattern: {
                        value:
                          // eslint-disable-next-line
                          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: "Enter a valid email address",
                      },
                      maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                    })}
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                </div>
              </div>
            </Col>
            {/* <Col lg={12}>
              <div className="form-group">
                <div className="form-control-wrap">
                  <label htmlFor="event_message" className="fs-6">
                    Contact Number <Required />
                  </label>
                  <div className="d-flex">
                    <Select
                      className="w-35"
                      value={selectedCountry}
                      options={countries}
                      onChange={handleChange}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          color: state.isSelected ? "white" : "black",
                          backgroundColor: state.isSelected ? "#007bff" : "white",
                          display: "flex",
                          alignItems: "center",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          display: "flex",
                          alignItems: "center",
                        }),
                      }}
                      components={{
                        Option,
                        SingleValue,
                      }}
                    />
                    <input
                      className="form-control border-0 text-white"
                      style={{ backgroundColor: "#00000047" }}
                      type="number"
                      id="phone_number"
                      onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                      placeholder="Enter Contact Number"
                      {...register("phone_number", {
                        required: "This is required",
                        maxLength: {
                          value: 15,
                          message: "You rich maximum contact number limit!",
                        },
                      })}
                      value={formData.phone_number}
                      onChange={(e) => {
                        if (e.target.value.length <= 15) {
                          setFormData({ ...formData, phone_number: e.target.value });
                        } else {
                          blockNumbers();
                        }
                      }}
                    />
                  </div>
                  {errors.phone_number && (
                    <span className="ff-italic text-danger" style={{ fontSize: "12px" }}>
                      {errors.phone_number.message}
                    </span>
                  )}
                </div>
              </div>
            </Col> */}
            <Col lg={12}>
              <div className="form-group">
                <div className="form-control-wrap mt-1">
                  <label htmlFor="message" className="fs-6">
                    Message
                  </label>
                  <textarea
                    className="form-control border-0 text-white h-100px"
                    style={{ backgroundColor: "#00000047" }}
                    type="text"
                    id="message"
                    placeholder="Enter Event Message"
                    {...register("message", {
                      pattern: {
                        message: "Only alphabets and spaces are allowed",
                      },
                      minLength: {
                        value: 5,
                        message: "Please enter at least 5 characters",
                      },
                    })}
                    maxLength={500}
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  />
                  {errors.message && <span className="invalid">{errors.message.message}</span>}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <div className="form-control-wrap mt-2">
                  {loading ? (
                    <Button disabled color="primary" className="justify-center w-100 rounded">
                      <Spinner size="sm" />
                      <span> Loading... </span>
                    </Button>
                  ) : (
                    <Button color="primary" className="justify-center w-100 rounded" type="submit">
                      Send Message
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  };
  const FormSubmit = async (form) => {
    const { name, email, country_id, phone_number, message } = form;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("country", country_id);
      formData.append("contact", phone_number);
      formData.append("message", message);
      const contactdata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/add-contactUs`, {
        method: "POST",
        body: formData,
      });
      const contaactinfo = await contactdata.json();
      if (contaactinfo && contaactinfo.status) {
        const submittedData = contaactinfo?.data;
        // console.log(submittedData);
        // toast.success("We will be reach soon!");
        resetForm();
        setFormActive("");
        handleSuccess();
      } else {
        toast.error(contaactinfo?.errors?.[0]);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };
  const blockNumbers = () => {};
  return (
    <>
      <Block className="">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiper2"
        >
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img3}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "auto" }}
              />
              <div
                className="position-absolute align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white w-100"
                style={{ top: 100, right: -40, zIndex: 1 }}
              >
                <div className="flex-column align-start justify-center w-60">
                  <div className="my-0">
                    <span className="fs-1 d-none d-md-block mb-3">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title1)}
                    </span>
                    <span className="fs-3 d-md-none">
                      {/* {showIpdm && bussiness?.CRM?.section1.map((item) => item?.title1)}
                      {showPhotography && bussiness?.photography?.section1.map((item) => item?.title1)}
                      {showConstruction && bussiness?.construction?.section1.map((item) => item?.title1)} */}
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title1)}
                    </span>
                  </div>
                  <div className="my-md-3 my-0">
                    <span className="fs-5 w-100 d-none d-md-block">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text1)}
                    </span>
                    <span className="fs-6 w-100 d-md-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text1)}
                    </span>
                  </div>
                  <div className="my-md-3 my-1">
                    <Button
                      className="w-150px justify-center rounded-3 py-2"
                      color="primary"
                      onClick={() => handleContact(1)}
                    >
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                    </Button>
                  </div>
                </div>
                <div className="w-35">{formActive === 1 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img1}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "auto" }}
              />
              <div
                className="position-absolute align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white w-100"
                style={{ top: 100, right: -40, zIndex: 1 }}
              >
                <div className="flex-column align-start justify-center w-60">
                  <div className="my-0">
                    <span className="fs-1 d-none d-md-block mb-3">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title2)}
                    </span>
                    <span className="fs-3 d-md-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title2)}
                    </span>
                  </div>
                  <div className="my-md-3 my-0">
                    <span className="fs-5 w-100 d-none d-md-block">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text2)}
                    </span>
                    <span className="fs-6 w-100 d-md-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text2)}
                    </span>
                  </div>
                  <div className="my-md-3 my-1">
                    <Button
                      className="w-150px justify-center rounded-3 py-2"
                      color="primary"
                      onClick={() => handleContact(2)}
                    >
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                    </Button>
                  </div>
                </div>
                <div className="w-35">{formActive === 2 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img2}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "auto" }}
              />
              <div
                className="position-absolute align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white w-100"
                style={{ top: 100, right: -40, zIndex: 1 }}
              >
                <div className="flex-column align-start justify-center w-60">
                  <div className="my-0">
                    <span className="fs-1 d-none d-md-block mb-3">
                      {/* {showIpdm && bussiness?.CRM?.section1.map((item) => item?.title3)}
                      {showPhotography && bussiness?.photography?.section1.map((item) => item?.title3)}
                      {showConstruction && bussiness?.construction?.section1.map((item) => item?.title3)} */}
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title3)}
                    </span>
                    <span className="fs-3 d-md-none">
                      {/* {showIpdm && bussiness?.CRM?.section1.map((item) => item?.title3)}
                      {showPhotography && bussiness?.photography?.section1.map((item) => item?.title3)}
                      {showConstruction && bussiness?.construction?.section1.map((item) => item?.title3)} */}
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title3)}
                    </span>
                  </div>
                  <div className="my-md-3 my-0">
                    <span className="fs-5 w-100 d-none d-md-block">
                      {/* {showIpdm && bussiness?.CRM?.section1.map((item) => item?.text3)}
                      {showPhotography && bussiness?.photography?.section1.map((item) => item?.text3)}
                      {showConstruction && bussiness?.construction?.section1.map((item) => item?.text3)} */}
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text3)}

                      {showConstruction && (
                        <ul className="ps-5 fs-6" style={{ listStyleType: "disc" }}>
                          <li>
                            {/* {bussiness?.construction?.section1.map((item) => item?.["text3.1"])} */}
                            {bussiness?.[activeVariable]?.section1?.map((item) => item?.["text3.1"])}
                          </li>
                          <li>
                            {/* {bussiness?.construction?.section1.map((item) => item?.["text3.2"])} */}
                            {bussiness?.[activeVariable]?.section1?.map((item) => item?.["text3.2"])}
                          </li>
                          <li>
                            {/* {bussiness?.construction?.section1.map((item) => item?.["text3.3"])} */}
                            {bussiness?.[activeVariable]?.section1?.map((item) => item?.["text3.3"])}
                          </li>
                          <li>
                            {/* {bussiness?.construction?.section1.map((item) => item?.["text3.4"])} */}
                            {bussiness?.[activeVariable]?.section1?.map((item) => item?.["text3.4"])}
                          </li>
                          <li>
                            {/* {bussiness?.construction?.section1.map((item) => item?.["text3.5"])} */}
                            {bussiness?.[activeVariable]?.section1?.map((item) => item?.["text3.5"])}
                          </li>
                        </ul>
                      )}
                    </span>
                    <span className="fs-6 w-100 d-md-none">
                      {/* {showIpdm && bussiness?.CRM?.section1.map((item) => item?.textsub3)}
                      {showPhotography && bussiness?.photography?.section1.map((item) => item?.textsub3)}
                      {showConstruction && bussiness?.construction?.section1.map((item) => item?.textsub3)} */}
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.textsub3)}
                    </span>
                  </div>
                  <div className="my-md-3 my-1">
                    <Button
                      className="w-150px justify-center rounded-3 py-2"
                      color="primary"
                      onClick={() => handleContact(3)}
                    >
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                    </Button>
                  </div>
                </div>
                <div className="w-35">{formActive === 3 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img4}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "auto" }}
              />
              <div
                className="position-absolute align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white w-100"
                style={{ top: 100, right: -40, zIndex: 1 }}
              >
                <div className="flex-column align-start justify-center w-60">
                  <div className="my-0">
                    <span className="fs-1 d-none d-md-block mb-3">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title4)}
                    </span>
                    <span className="fs-3 d-md-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title4)}
                    </span>
                  </div>
                  <div className="my-md-3 my-0">
                    <span className="fs-5 w-100 d-none d-md-block">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text4)}
                    </span>
                    <span className="fs-6 w-100 d-md-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text4)}
                    </span>
                  </div>
                  <div className="my-md-3 my-1">
                    <Button
                      className="w-150px justify-center rounded-3 py-2"
                      color="primary"
                      onClick={() => handleContact(4)}
                    >
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                    </Button>
                  </div>
                </div>
                <div className="w-35">{formActive === 4 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img5}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "auto" }}
              />
              <div
                className="position-absolute align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white w-100"
                style={{ top: 100, right: -40, zIndex: 1 }}
              >
                <div className="flex-column align-start justify-center w-60">
                  <div className="my-0">
                    <span className="fs-1 d-none d-md-block mb-3">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title5)}
                    </span>
                    <span className="fs-3 d-md-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title5)}
                    </span>
                  </div>
                  <div className="my-md-3 my-0">
                    <span className="fs-5 w-100 d-none d-md-block">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text5)}
                    </span>
                    <span className="fs-6 w-100 d-md-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text5)}
                    </span>
                  </div>
                  <div className="my-md-3 my-1">
                    <Button
                      className="w-150px justify-center rounded-3 py-2"
                      color="primary"
                      onClick={() => handleContact(5)}
                    >
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                    </Button>
                  </div>
                </div>
                <div className="w-35">{formActive === 5 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiper2 swiperss"
        >
          <SwiperSlide className={`swiper-slidess`}>
            <img src={img3} />
          </SwiperSlide>
          <SwiperSlide className={`swiper-slidess`}>
            <img src={img1} />
          </SwiperSlide>
          <SwiperSlide className={`swiper-slidess`}>
            <img src={img2} />
          </SwiperSlide>
          <SwiperSlide className={`swiper-slidess`}>
            <img src={img4} />
          </SwiperSlide>
          <SwiperSlide className={`swiper-slidess`}>
            <img src={img5} />
          </SwiperSlide>
        </Swiper>
      </Block>
    </>
  );
};

export default BusinessPortal;
