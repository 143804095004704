import React, { useEffect, useState } from "react";
import { Block, BlockTitle, Icon, PreviewCard, UserAvatar } from "../../components/Component";
import {
  Badge,
  Button,
  Card,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import Content from "../../layout/content/Content";
import { getReview } from "../../http/get/getApi";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import Required from "../components/Required";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import NoFounds from "../../images/Login-No-Background.png";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { getCookie, getInitiatals } from "../../utils/Utils";
import book from "../../jsonfiles/Booking.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const Review = ({ companyData }) => {
  const { id } = useParams();
  const [modalSm, setModalSm] = useState(false);
  const toggleSm = () => setModalSm(!modalSm);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    rating: "",
    title: "",
    description: "",
  });
  const reviewClient = useQueryClient();
  const resetForm = () => {
    setFormData({
      rating: "",
      title: "",
      description: "",
    });
    reset({});
  };

  const activeVariable = getActiveEnvironmentVariable();



  useEffect(() => {
    reset(formData);
  }, [formData]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    data: reviewData,
    isLoading: reviewDataLoading,
    isError: reviewDataError,
  } = useQuery({
    queryKey: ["get-review-list", id],
    queryFn: () => getReview({ id: id }),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const onFormSubmit = async (form) => {
    const { rating, title, description } = form;
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("company_id", id);
      formData.append("rating", rating);
      formData.append("title", title);
      formData.append("review", description);
      const reviewdata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/add-reviews`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });
      const reviewinfo = await reviewdata.json();
      if (reviewinfo && reviewinfo.status) {
        const submittedData = reviewinfo?.data;
        reviewClient.invalidateQueries("get-review-list");
        toast.success("Review Sent Successfully");
        resetForm();
        toggleSm();
      } else {
        toast.error(reviewinfo?.errors?.[0]);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };
  const cookieValue = Cookies.get("authToken");
  const showSignMessege = () => {
    if (!cookieValue) {
      Swal.fire({
        title: "You are not Sign-In",
        text: book?.[activeVariable]?.section10?.map((item) => item?.text1),
       
        imageUrl: NoFounds,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Sign-In Image",
        confirmButtonText: "Sign-In",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
          Cookies.set("cookieVal", "clientRole3", {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/${id}`, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          window.location = process.env.REACT_APP_ACCOUNT_URL;
        }
      });
    } else {
      toggleSm();
    }
  };

  return (
    <div className="mb-5">
      <Content>
        <BlockTitle page className="mb-4 text-center" tag="h3">
          Reviews
        </BlockTitle>
        <Row className="border h-500px mb-1 overflow-auto">
          <Col lg="5" className="justify-center">
            <div className="d-flex flex-column">
              <div className="d-flex gy-3 w-400px my-3">
                <div className="progress-amount me-4">
                  <h1 className="title">{reviewData?.data?.review ? reviewData.data.review.length + ".0" : "0.0"}</h1>
                  <ul className="rating">
                    <li>
                      <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                    </li>
                    <li>
                      <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                    </li>
                    <li>
                      <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                    </li>
                    <li>
                      <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                    </li>
                    <li>
                      <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                    </li>
                  </ul>
                  <span className="sub-text mt-1">
                    <Icon name="users-fill"></Icon>{" "}
                    {reviewData?.data?.review ? reviewData.data.review.length + " Total" : "0 Total"}
                  </span>
                </div>
                {reviewData?.data?.review ? (
                  <div className="rating-progress-bar gy-1 w-100">
                    <div className="progress-rating">
                      <div className="progress-rating me-2">5</div>
                      <Progress value={100} color="primary" className="progress-lg"></Progress>
                    </div>
                    <div className="progress-rating">
                      <div className="progress-rating me-2">4</div>
                      <Progress value={0} color="primary" className="progress-lg"></Progress>
                    </div>
                    <div className="progress-rating">
                      <div className="progress-rating me-2">3</div>
                      <Progress value={0} color="primary" className="progress-lg"></Progress>
                    </div>
                    <div className="progress-rating">
                      <div className="progress-rating me-2">2</div>
                      <Progress value={0} color="primary" className="progress-lg"></Progress>
                    </div>
                    <div className="progress-rating">
                      <div className="progress-rating me-2">1</div>
                      <Progress value={0} color="primary" className="progress-lg"></Progress>
                    </div>
                  </div>
                ) : (
                  <div className="rating-progress-bar gy-1 w-100">
                    <div className="progress-rating">
                      <div className="progress-rating me-2">5</div>
                      <Progress value={0} color="primary" className="progress-lg"></Progress>
                    </div>
                    <div className="progress-rating">
                      <div className="progress-rating me-2">4</div>
                      <Progress value={0} color="primary" className="progress-lg"></Progress>
                    </div>
                    <div className="progress-rating">
                      <div className="progress-rating me-2">3</div>
                      <Progress value={0} color="primary" className="progress-lg"></Progress>
                    </div>
                    <div className="progress-rating">
                      <div className="progress-rating me-2">2</div>
                      <Progress value={0} color="primary" className="progress-lg"></Progress>
                    </div>
                    <div className="progress-rating">
                      <div className="progress-rating me-2">1</div>
                      <Progress value={0} color="primary" className="progress-lg"></Progress>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <BlockTitle className="fs-4">
              
                 { book?.[activeVariable]?.section5?.map((item) => item?.text1)}
                </BlockTitle>
                <p className="w-75">
              
                  { book?.[activeVariable]?.section5?.map((item) => item?.text2)}
                  {" "}
                </p>
                <Button color="primary" className="rounded-3" onClick={showSignMessege}>
              
                  { book?.[activeVariable]?.section5?.map((item) => item?.text3)}
                </Button>
                <Modal size="md" isOpen={modalSm} toggle={toggleSm}>
                  <ModalHeader
                    className="justify-between"
                    toggle={toggleSm}
                    close={
                      <button className="close" onClick={toggleSm}>
                        <Icon name="cross" />
                      </button>
                    }
                  >
                    Create Review
                  </ModalHeader>
                  <ModalBody>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                      <div>
                        <Label className="d-block fs-6 fw-bold mb-0">
                          Overall rating
                          <Required />
                        </Label>
                        <div className="ratingUI">
                          {["5", "4", "3", "2", "1"].map((value) => (
                            <React.Fragment key={value}>
                              <input
                                required
                                value={value}
                                name="rating"
                                id={`star${value}`}
                                type="radio"
                                onChange={() => setFormData({ ...formData, rating: value })}
                              />
                              <label htmlFor={`star${value}`}></label>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                      <hr className="mt-0" />
                      <div>
                        <Label className="d-block fs-6 fw-bold mb-0">Add headline</Label>
                        <div>
                          <Input
                            style={{ cursor: "pointer" }}
                            size="lg"
                            type="text"
                            maxLength={30}
                            value={formData.title}
                            className="rounded-3 flex-grow-1 rounded-0 my-1 w-75"
                            placeholder="Tell us about your experience!"
                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                          />
                        </div>
                      </div>
                      <hr />
                      <div>
                        <Label className="d-block fs-6 fw-bold mb-0">
                       
                  { book?.[activeVariable]?.section5?.map((item) => item?.text4)}
                          </Label>
                        <div>
                          <Input
                            style={{ cursor: "pointer" }}
                            size="lg"
                            type="textarea"
                            maxLength={500}
                            value={formData.description}
                            className="rounded-3 flex-grow-1 rounded-0 my-1 w-75"
                            placeholder="Write your thoughts in here..."
                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="justify-end mt-4">
                        {loading ? (
                          <Button disabled color="primary">
                            <Spinner size="sm" />
                            <span> Loading... </span>
                          </Button>
                        ) : (
                          <Button color="primary" type="submit">
                            Add Review
                          </Button>
                        )}
                      </div>
                    </form>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </Col>
          <Col lg="7" className="overflow-auto h-475px" style={{ scrollbarWidth: "none" }}>
            {reviewDataLoading ? (
              <div className="mx-1 align-center">
                <Spinner size="sm" color="light" />
                <span className="px-1">Loading...</span>
              </div>
            ) : reviewDataError ? (
              <div>Error loading reviews. Please try again.</div>
            ) : reviewData?.data?.review ? (
              reviewData?.data?.review.map((name, index) => (
                <div className="card-inner py-3" key={index}>
                  <div className="d-flex ">
                    <div className={`user-avatar ${index % 2 === 0 ? "bg-purple" : "bg-info"} me-3`}>
                      {/* <span>{name?.client_name.charAt(1).toUpperCase()}</span> */}
                      <UserAvatar text={getInitiatals(name?.client_name)} />
                    </div>
                    <div className="fake-class w-100">
                      <div className="d-flex justify-between w-100 mt-1">
                        <div className="">
                          <h5 className="mt-0 d-flex flex-column">
                            <span>{name?.client_name}</span>
                            <span className="fw-light fs-6">{name?.designation}</span>
                          </h5>
                        </div>
                        <ul className="rating mt-0 mb-2">
                          {name?.rating && (
                            <li>
                              {/* Filled stars */}
                              {[...Array(parseInt(name?.rating))].map((_, index) => (
                                <Icon name="star-fill" key={index}></Icon>
                              ))}
                              {/* Empty stars */}
                              {[...Array(5 - parseInt(name?.rating))].map((_, index) => (
                                <Icon name="star-outline" key={index + parseInt(name?.rating)}></Icon>
                              ))}
                            </li>
                          )}
                        </ul>
                      </div>
                      <h6 className="mt-2 d-flex align-center mb-0 mb-1">
                        <span>{name?.title}</span>
                      </h6>
                      <p className="text-soft mb-0">{name?.review}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="h-100 align-center justify-center">
                <p className="text-muted fs-2 fw-light">No reviews available</p>
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default Review;
