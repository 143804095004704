import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ShimmerThumbnail } from "react-shimmer-effects";
import CookieSiteSettings from "../../utils/cookieUtils";
import {Links} from '../../jsonfiles/Links';
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const LogoFetch = () => {
  
  
  const activeVariable = getActiveEnvironmentVariable();

  const logodefault = Links?.[activeVariable]?.Home?.[0]?.url4;
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchLogoFromCookie = () => {
    const siteSettings = CookieSiteSettings();
    return siteSettings?.meta?.sidebar_logo || null;
  };

  useEffect(() => {
    setLogo(fetchLogoFromCookie());
    setLoading(false);

    const interval = setInterval(() => {
      const newLogo = fetchLogoFromCookie();
      if (newLogo !== logo) {
        setLogo(newLogo);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [logo]);

  return (
    <>
      {loading ? (
        <div className="p-5">
          <ShimmerThumbnail height={5} width={70} />
        </div>
      ) : (
        logo && (
          <div className="nk-apps-brand mt-1">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link p-0">
              <img className="logo-light" src={`${process.env.REACT_APP_API_URL}${logo}` || logodefault} alt="logo" />
              <img
                className="h-75 logo-dark logo-img"
                style={{ maxHeight: "100px" }}
                src={`${process.env.REACT_APP_API_URL}${logo}` || logodefault}
                alt="logo-dark"
              />
            </Link>
          </div>
        )
      )}
    </>
  );
};

export default LogoFetch;
