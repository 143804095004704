import React, { useState, useEffect } from "react";
import { Alert, Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { getCompanyFeaturePerformance } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
// import placeHolderImg from "../../images/Profile_Picture.png";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import NoResult from "../../images/No Result.png";
// import vidDJ from "../../images/vidDJ.mp4";
import CookieSiteSettings from "../../utils/cookieUtils";
import { Links } from "../../jsonfiles/Links";
import Home from "../../jsonfiles/Home.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const TopDjs = () => {

  const activeVariable = getActiveEnvironmentVariable();

  const [currency, setCurrency] = useState("sdf");
  const vidDJ = Links?.[activeVariable]?.Home?.[0]?.url3;
 
  const placeHolderImg = Links?.[activeVariable]?.Home?.[0]?.url1;
 
  console.log("placeHolderImg");
  useEffect(() => {
    const getCurrency = async () => {
      const currencyValue = CookieSiteSettings();
      setCurrency(currencyValue?.other?.currency);
    };

    getCurrency();
  }, []);
  const [headerLinks, setHeaderLinks] = useState([]);

  console.log("first");
  console.log("headerLinks", headerLinks);
  const navigate = useNavigate();

  const {
    data: featuredDJs,
    isLoading: featuredDJsDjsLoading,
    isError: featuredDJsDjsError,
  } = useQuery({
    queryKey: ["get-featuredDJsDjs"],
    queryFn: () => getCompanyFeaturePerformance({ type: 10 }),
    staleTime: Infinity,
  });
  console.log("featuredDJs", featuredDJs);
  return (
    <>
      <Card className="border mt-0">
        <div className="pt-4 px-4" style={{ backgroundColor: "#f8f9fa" }}>
          <div className="text-center mb-4">
                <h2 className="fs-2 lead-text mb-3">
                  {Home?.[activeVariable]?.section2?.map((item) => item?.title)}
                  </h2>
                <p className="lead text-muted">
                  {Home?.[activeVariable]?.section2?.map((item) => item?.Description)}
                  </p>   
          </div>
          {featuredDJsDjsLoading ? (
            <Row>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
            </Row>
          ) : featuredDJsDjsError ? (
            <div className="d-flex flex-column justify-center list-group-item align-center w-100 h-100 my-1 px-2">
              <img src={placeHolderImg} alt={`No Found Image`} className="mb-1" style={{ width: "150px" }} />
              {/* <span className="bi bi-dot fw-medium">{`No ${type} Found`}</span> */}
            </div>
          ) : (
            <Row>
              <div className="mb-4">
                {featuredDJs?.status === false ? (
                  <Col xs="12">
                    <Alert color="white" className="text-center">
                      <h4 className="alert-heading">Oops! Something went wrong.</h4>
                      <p>
                        {/* {Home?.construction?.section2.map((item) => item?.title2)} */}
                        {Home?.[activeVariable]?.section2?.map((item) => item?.title2)}
                        </p>
                      <Button color="primary" onClick={() => window.location.reload()}>
                        Refresh
                      </Button>
                    </Alert>
                  </Col>
                ) : (
                  <Card style={{ backgroundColor: "#F8F9FA" }}>
                    {featuredDJs?.data?.Platinum.length < 0 ? (
                      <div className="d-flex justify-center align-center h-100px fs-5">No Data Found</div>
                    ) : (
                      <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className="p-0 m-0 "
                        containerClass=""
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1440,
                            },
                            items: 4,
                            partialVisibilityGutter: 40,
                          },
                          mobile: {
                            breakpoint: {
                              max: 600,
                              min: 0,
                            },
                            items: 1,
                            partialVisibilityGutter: 30,
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 600,
                            },
                            items: 2,
                            partialVisibilityGutter: 20,
                          },
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        // swipeable
                      >
                        {featuredDJs?.data?.Platinum.map((profile) => (
                          <div key={profile?.user_id} className="shadow-sm h-100 mx-2">
                            <Card className="h-100">
                              <CardImg
                                top
                                src={profile?.user_profile?.profile_image_path || placeHolderImg}
                                alt={profile?.user_profile?.company_name}
                                className="rounded-top"
                                style={{ height: "250px", objectFit: "cover", cursor: "pointer" }}
                              />
                              <CardBody className="p-4 d-flex flex-column justify-content-between">
                                <div style={{ cursor: "pointer" }}>
                                  <CardTitle tag="h5">{profile?.user_profile?.company_name}</CardTitle>
                                  <CardText className="text-muted">{profile?.user_profile?.city || "---"}</CardText>
                                  <CardText className="mb-3">
                                    Starting from: {currency}{" "}
                                    {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(
                                      profile?.user_profile?.min_package_price || 0
                                    )}
                                  </CardText>
                                  <CardText>
                                    {profile?.user_profile?.uniqueness &&
                                    profile?.user_profile?.uniqueness.length > 200 ? (
                                      <>
                                        {profile?.user_profile?.uniqueness.substring(0, 200)}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className="text-primary fw-bold"
                                          onClick={() => navigate(`/dj-profiles/specificDj/${profile?.user_id}`)}
                                        >
                                          ...
                                        </span>
                                      </>
                                    ) : (
                                      profile?.user_profile?.uniqueness || "----"
                                    )}
                                  </CardText>
                                </div>
                                <div className="mt-3">
                                  <Button
                                    color="primary"
                                    className="me-2 rounded-pill w-100 d-flex justify-content-center align-items-center"
                                    onClick={() => navigate(`/dj-profiles/specificDj/${profile?.user_id}`)}
                                  >
                                    View Details
                                  </Button>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </Card>
                )}
              </div>
            </Row>
          )}
        </div>
      </Card>

      <div
        style={{
          // minHeight: "90.5vh",
          position: "relative",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
        >
          <source src={vidDJ} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <Card className="border mt-0">
        <div className="p-4 px-4" style={{ backgroundColor: "#f8f9fa" }}>
          <div className="text-center mb-4">
           <h2 className="fs-2 lead-text mb-3">
            {Home?.[activeVariable]?.section3?.map((item) => item?.title)}
            </h2>
            <p className="lead text-muted">
              {Home?.[activeVariable]?.section3?.map((item) => item?.Description)}
              </p>
          </div>
          {featuredDJsDjsLoading ? (
            <Row>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
            </Row>
          ) : featuredDJsDjsError ? (
            <div className="d-flex flex-column justify-center list-group-item align-center w-100 h-100 my-1 px-2">
              <img src={placeHolderImg} alt={`No Found Image`} className="mb-1" style={{ width: "150px" }} />
              {/* <span className="bi bi-dot fw-medium">{`No ${type} Found`}</span> */}
            </div>
          ) : (
            <Card style={{ backgroundColor: "#F8F9FA" }}>
              {featuredDJs?.data?.Platinum.length < 0 ? (
                <div className="d-flex justify-center align-center h-100px fs-5">No Data Found</div>
              ) : (
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className="p-0  m-0"
                  containerClass=""
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1440,
                      },
                      items: 4,
                      partialVisibilityGutter: 40,
                    },
                    mobile: {
                      breakpoint: {
                        max: 600,
                        min: 0,
                      },
                      items: 1,
                      partialVisibilityGutter: 30,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 600,
                      },
                      items: 2,
                      partialVisibilityGutter: 20,
                    },
                  }}
                  rewind={false}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  // swipeable
                >
                  {featuredDJs?.data?.recent_plans?.slice(0, 5).map((profile) => (
                    <div key={profile?.user_id} className="shadow-sm h-100 mx-2">
                      <Card className="h-100">
                        <CardImg
                          top
                          src={profile?.user_profile?.profile_image_path || placeHolderImg}
                          alt={profile?.user_profile?.company_name}
                          className="rounded-top"
                          style={{ height: "250px", objectFit: "cover", cursor: "pointer" }}
                        />
                        <CardBody className="p-4 d-flex flex-column justify-content-between">
                          <div style={{ cursor: "pointer" }}>
                            <CardTitle tag="h5">{profile?.user_profile?.company_name}</CardTitle>
                            <CardText className="text-muted">{profile?.user_profile?.city || "---"}</CardText>
                            <CardText className="mb-3">
                              Starting from: {currency}{" "}
                              {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(
                                profile?.user_profile?.min_package_price || 0
                              )}
                            </CardText>
                            <CardText>
                              {profile?.user_profile?.uniqueness && profile?.user_profile?.uniqueness.length > 200 ? (
                                <>
                                  {profile?.user_profile?.uniqueness.substring(0, 200)}
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="text-primary fw-bold"
                                    onClick={() => navigate(`/dj-profiles/specificDj/${profile?.user_id}`)}
                                  >
                                    ...
                                  </span>
                                </>
                              ) : (
                                profile?.user_profile?.uniqueness || "----"
                              )}
                            </CardText>
                          </div>
                          <div className="mt-3">
                            <Button
                              color="primary"
                              className="me-2 rounded-pill w-100 d-flex justify-content-center align-items-center"
                              onClick={() => navigate(`/dj-profiles/specificDj/${profile?.user_id}`)}
                            >
                              View Details
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Carousel>
              )}
            </Card>
          )}
        </div>
      </Card>
    </>
  );
};

export default TopDjs;
