import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import Carousel from "react-multi-carousel";
import NoFounds from "../../images/No Result.png";
import Content from "../../layout/content/Content";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, TooltipComponent } from "../../components/Component";
import { getsitesetting } from "../../http/get/getApi";
import book from "../../jsonfiles/Booking.json"
import CookieSiteSettings from "../../utils/cookieUtils";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const Packages = ({ packageData, onsubmit }) => {
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    const getCurrency = async () => {
      const currencyValue = CookieSiteSettings();
      setCurrency(currencyValue?.other?.currency);
    };
    getCurrency();
  }, []);

  const activeVariable = getActiveEnvironmentVariable();



  return (
    <div id="pkg">
      <BlockHead size="sm" className="my-2 mx-2">
        <BlockBetween className="g-3 justify-center">
          <BlockHeadContent className="px-3">
            <BlockTitle page>
              <h3 className="text-center">
               
                 {book?.[activeVariable]?.section4?.map((item) => item?.text1)}
                </h3>
            </BlockTitle>
            <p className="">
              {/* "Unleash the Beats for an Unforgettable Night!" Get ready to dance the night away with our exclusive DJ
              party packages, designed to bring the perfect soundtracks to your celebration. From pulsating beats to
              smooth transitions, we cater to every vibe. Whether it’s a wedding, corporate event, or birthday bash, our
              professional DJs have you covered with top-notch sound systems and custom playlists to keep the party
              going! */}
            
                 {book?.[activeVariable]?.section4?.map((item) => item?.text2)}
            </p>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <div className="row ">
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className="p-0 m-0 justify-center"
          containerClass=""
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1440,
              },
              items: 4,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 600,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 600,
              },
              items: 2,
              partialVisibilityGutter: 20,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          // swipeable
        >
          {packageData.map((card, index) => (
            <div className="mx-2" key={index}>
              <div className="package-cardUI text-center bg-transparent w-100 p-1 h-375px">
                <div className="package-card-innerUI">
                  <div className="package-card-frontUI border border-primary rounded-3 shadow-sm">
                    <img
                      src={card?.package_file || NoFounds}
                      alt={card?.package_name}
                      className={`card-img-top img-fluid h-275px object-fit-contain`}
                    />
                    <div className="card-body mt-1">
                      <h5 className="card-title">
                        {card?.package_name.length > 28 ? (
                          <>
                            {card?.package_name.substring(0, 25)}
                            <span className="text-primary fw-bold">...</span>
                            <TooltipComponent id="pkgTitle" text={card?.package_name} direction="bottom" />
                          </>
                        ) : (
                          card?.package_name || "----"
                        )}
                      </h5>
                      <p className="card-text">
                        Package: {currency}
                        {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(card?.package_price)}
                      </p>
                    </div>
                  </div>
                  <div className="package-card-backUI border border-primary rounded-3 p-2 shadow-sm">
                    <div>
                      <p>{card?.description}</p>
                      <div>
                        <Button color="primary" onClick={onsubmit}>
                          Book Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* 
          {cardData.map((card, index) => (
            <div className="" key={index}>
              <div className="package-cardUI text-center bg-transparent w-100 p-1 h-375px">
                <div className="package-card-innerUI">
                  <div className="package-card-frontUI rounded-5">
                    <img
                      src={card?.imgSrc || NoFounds}
                      alt={card?.title}
                      className={`card-img-top img-fluid custom-rounded-top h-275px object-fit-cover`}
                    />
                    <div className="card-body mt-1">
                      <h5 className="card-title">{card?.title}</h5>
                      <p className="card-text">Package: {card?.price}</p>
                    </div>
                  </div>

                  <div className="package-card-backUI p-2">
                    <div>
                      <p>{card?.content}</p>
                      <div>
                        <Button color="primary" onClick={onsubmit}>
                          Buy Now
                        </Button>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </Carousel>
      </div>
    </div>
  );
};

export default Packages;
