import React, { useContext, useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { Card, Spinner } from "reactstrap";
import { Block, Row, Col, PreviewAltCard } from "../../components/Component";
import DjEnquriy from "./DjEnquriy";
import SubMenu from "./SubMenu";
import Professionalsummery from "./Professionalsummery";
import Portfolio from "./Portfolio";
import Review from "./Review";
import ImageCarousel from "./ImageCarousel";
import { useQuery } from "react-query";
import { getAllCategories, getAllCompanies, getPackages } from "../../http/get/getApi";
import { useNavigate, useParams } from "react-router";
import { ShimmerSimpleGallery, ShimmerThumbnail } from "react-shimmer-effects";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { createcontext } from "../../layout/Index";
import Packages from "./Packages";
import NoFounds from "../../images/Login-No-Background.png";
import NoResults from "../../images/No Result.png";
import { BlockBetween, BlockHeadContent, BlockTitle, BlockHead } from "../../components/Component";
import { toast } from "react-toastify";
import book from "../../jsonfiles/Booking.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const SelectDJ = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const cookieValue = Cookies.get("authToken");
  const { heroSection, setHeroSection } = useContext(createcontext);

  useEffect(() => {
    setHeroSection(true);
  }, []);

  
  const activeVariable = getActiveEnvironmentVariable();

  //get specific companies data from its id
  const {
    data: companyData,
    isLoading: companyDataLoading,
    isError: companyDataError,
  } = useQuery({
    queryKey: ["get-company-by-id", id],
    queryFn: () => getAllCompanies({ id: id }),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const {
    data: packageData,
    isLoading: packageDataLoading,
    isError: packageDataError,
  } = useQuery({
    queryKey: ["get-package-by-id", id],
    queryFn: () => getPackages({ id: id }),
    keepPreviousData: true,
    staleTime: Infinity,
  });
  const [selectedSlot, setSelectedSlot] = useState(null);

  const [selectedSlots, setSelectedSlots] = useState({
    morning: false,
    afternoon: false,
    night: false,
    all: false,
  });
  const [selectedDate, setSelectedDate] = useState(null); // Define selectedDate here

  // Function to format time
  const formatTime = (date) => {
    if (!date) return "";
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    return new Date(date).toLocaleTimeString([], options);
  };

  const {
    data: categoryList,
    isLoading: categoryListLoading,
    isError: categoryListError,
  } = useQuery({
    queryKey: ["get-category-list"],
    queryFn: () => getAllCategories(),
    staleTime: Infinity,
  });

  const showSignMessege = () => {
    if (!cookieValue) {
      Swal.fire({
        title: "You are not Sign-In",
        text:  book?.[activeVariable]?.section10?.map((item) => item?.text1),
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Sign-In Image",
        confirmButtonText: "Sign-In",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1-day expiration for the cookie
          Cookies.set("cookieVal", "clientRole3", {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/${id}`, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          window.location = process.env.REACT_APP_ACCOUNT_URL;
        }
      });
    } else {
      return navigate(`/dj-profiles/specificDj/${id}/dj-bookevent`, { state: { companyData } });
    }
  };

  return (
    <React.Fragment>
      <Head title="Default Dashboard" />
      {companyDataLoading ? (
        <Row>
          <Col lg={8}>
            <div className="p-5">
              <ShimmerThumbnail height={700} width={1000} />
            </div>
          </Col>
          <Col lg={4}>
            <div className="p-5">
              <ShimmerThumbnail height={600} width={400} />
            </div>
          </Col>
        </Row>
      ) : (
        <Block>
          <div id="home">
            <Row className="g-0">
              <Col lg="8">
                <PreviewAltCard className="card-bordered h-100">
                  <ImageCarousel companyData={companyData?.data?.[0]} />
                </PreviewAltCard>
              </Col>
              <Col lg="4">
                <PreviewAltCard className="card-bordered h-100">
                  <DjEnquriy
                    companyData={companyData?.data?.[0]}
                    onsubmit={showSignMessege}
                    packageData={packageData}
                    packageDataLoading={packageDataLoading}
                    packageDataError={packageDataError}
                    categoryList={categoryList}
                    categoryListLoading={categoryListLoading}
                    categoryListError={categoryListError}
                  />
                </PreviewAltCard>
              </Col>
              <SubMenu />
            </Row>
          </div>
          <Row className="g-0">
            <Col xxl="12" className="m-0">
              <Card className="card-bordered card-full md-wid overflow-auto">
                <Professionalsummery companyData={companyData?.data?.[0]} />
              </Card>
            </Col>
            <Col xxl="12" className="m-0">
              <Card className="card-bordered card-full md-wid overflow-auto">
                <Portfolio companyData={companyData?.data?.[0]} companyDataLoading={companyDataLoading} />
              </Card>
            </Col>
            <Col xxl="12" className="m-0">
              {packageDataLoading ? (
                <div className="mx-1 align-center">
                  <ShimmerSimpleGallery imageType="circular" imageHeight={150} caption />
                  <ShimmerSimpleGallery imageType="circular" imageHeight={150} caption />
                  <ShimmerSimpleGallery imageType="circular" imageHeight={150} caption />
                </div>
              ) : packageDataError ? (
                <div>Error loading packages.Please try again.</div>
              ) : packageData?.data?.packages.length ? (
                <Card className="card-bordered px-2 py-3">
                  <Packages packageData={packageData?.data?.packages} onsubmit={showSignMessege} />
                </Card>
              ) : (
                <BlockHead size="sm" className="my-4 mx-5 px-1">
                  <BlockBetween className="g-3 justify-center">
                    <BlockHeadContent className="px-3">
                      <BlockTitle page>
                        <h3 className="text-center">Packages</h3>
                      </BlockTitle>
                    </BlockHeadContent>
                  </BlockBetween>
                  <div className="text-center my-2 mt-4">
                    <img src={NoResults} height={150} width={190} alt="Image not found"></img>
                  </div>
                </BlockHead>
              )}
            </Col>
            <Col xxl="12" className="m-0">
              <div id="review">
                <Card className="card-bordered card-full overflow-auto">
                  <Review companyData={companyData?.data?.[0]} />
                </Card>
              </div>
            </Col>
          </Row>
        </Block>
      )}
    </React.Fragment>
  );
};

export default SelectDJ;
