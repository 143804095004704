import React, { Children, useEffect, useState } from "react";
import { Block } from "../../components/Component";
import { Button, Card, CardBody, CardText, Col, Row, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { TooltipComponent } from "../../components/Component";
import Icon from "../../components/icon/Icon";
import Select, { components } from "react-select";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CalenderApp from "../../components/partials/calender/Calender";
import book from "../../jsonfiles/Booking.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const Required = () => {
  return (
    <>
      <span style={{ color: "red" }}> *</span>
    </>
  );
};
const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" />
    {props.data.label}
  </components.Option>
);
const DjEnquriy = ({
  companyData,
  onsubmit,
  packageData,
  packageDataError,
  packageDataLoading,
  categoryList,
  categoryListLoading,
  categoryListError,
}) => {
  const [formDataSlot, setFormDataSlot] = useState({ startTime: null, endTime: null, eventDate: null });

  const [modalTop, setModalTop] = useState(false);
  const toggleTop = () => setModalTop(!modalTop);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    country_id: "",
    event_type: "",
    event_name: "",
    event_package: "",
    event_message: "",
    event_package_amount: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(formData);
  }, []);

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      country_id: "",
      event_type: "",
      event_name: "",
      event_package: "",
      event_message: "",
      event_package_amount: "",
    });
    reset({});
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`)
      .then((response) => response.json())
      .then((data) => {
        const countriesData = data.data.countries
          .filter((country) => country.id === 101 || country.id === 231)
          .map((country) => ({
            value: country.shortname,
            label: "+" + country.phonecode,
            id: country.id,
            icon: `https://flagsapi.com/${country.shortname}/shiny/64.png`,
          }));
        setCountries(countriesData);
        setSelectedCountry(countriesData?.[0]);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    setFormData({ ...formData, country_id: countries?.[0]?.id });
  }, [countries]);

  const handleChange = (value) => {
    setSelectedCountry(value);
    setFormData({ ...formData, country_id: value?.id });
  };

  const onFormSubmit = async (form) => {
    const {
      name,
      email,
      phone_number,
      country_id,
      event_type,
      event_name,
      event_package,
      event_package_amount,
      event_message,
    } = form;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      console.log(country_id);
      formData.append("country", country_id);
      formData.append("event_type", event_type);
      formData.append("event_name", event_name);
      formData.append("event_package", event_package);
      formData.append("event_package_amount", event_package_amount);
      formData.append("enquiry_message", event_message);
      formData.append("company_id", id);
      const enquirydata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/enquiry/add-enquiry`, {
        method: "POST",
        body: formData,
      });
      const enquiryinfo = await enquirydata.json();
      if (enquiryinfo && enquiryinfo.status) {
        const submittedData = enquiryinfo?.data;
        toast.success("Inquiry Sent Successfully");
        resetForm();
      } else {
        toast.error(enquiryinfo?.errors?.[0]);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };
  const blockNumbers = (val) => {};

  const handlePackagePrice = (e) => {
    const value = parseInt(e.target.value);
    const othervalue = e.target.value;
    const selectedPackage = packageData?.data?.packages.find((item) => item?.id === value);
    const selectedPackageAmount = selectedPackage?.package_price;
    if (value) {
      setFormData({
        ...formData,
        event_package: value,
        event_package_amount: selectedPackageAmount,
      });
    } else if (othervalue === "other") {
      setFormData({
        ...formData,
        event_package: "other",
        event_package_amount: 0,
      });
    } else {
      setFormData({
        ...formData,
        event_package: " ",
        event_package_amount: "",
      });
    }
    clearErrors(e.target.event_package);
  };
  const [isCalendarVisible, setCalendarVisible] = useState(false);

  const [showFirstModal, setShowFirstModal] = useState(true);

  const handleOpenSecondModal = () => {
    setShowFirstModal(false); // Replace the first modal
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={props.data.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const activeVariable = getActiveEnvironmentVariable();



  return (
    <div>
      <Block size="lg">
        <Card className="align-center">
          <CardBody className="card-inner p-0">
            <CardText>
              <div className="d-flex align-center justify-between">
                <span>
                  
                  { book?.[activeVariable]?.section8?.map((item) => item?.text1)}
                  </span>
                <button class="BtnCalendarUI" color="primary" onClick={toggleTop}>
                  <div class="signCalendarUI">
                    <Icon name="calendar" className="text-white"></Icon>
                  </div>

                  <div class="textCalendarUI">Visit Calendar</div>
                </button>
              </div>
              {isCalendarVisible && <calendar />}
            </CardText>
            <Modal isOpen={modalTop} toggle={toggleTop} size="xl" style={{ height: 600 }}>
              <ModalHeader
                toggle={toggleTop}
                close={
                  <button className="close ml-auto" onClick={toggleTop}>
                    <Icon name="cross" />
                  </button>
                }
              >
             
               { book?.[activeVariable]?.section8?.map((item) => item?.text2)}
              </ModalHeader>

              <ModalBody style={{ height: "600px" }}>
                <CalenderApp setFormDataSlot={setFormDataSlot} formDataSlot={formDataSlot} />
              </ModalBody>
            </Modal>
            <div className="d-flex justify-around align-center ">
              <div className="w-100 my-1">
                <Button color="primary" className="rounded justify-center w-100" onClick={onsubmit}>
                  Book Now
                </Button>
              </div>
            </div>
            <Card className="my-2 align-center">
              <div className="card-bordered w-100 p-3 border-1 border-primary">
                <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                  <Row>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Full Name <Required />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="name"
                            placeholder="Enter Full Name"
                            {...register("name", {
                              required: "This field is required",
                              pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message: "Only alphabets and spaces are allowed",
                              },
                              minLength: {
                                value: 3,
                                message: "Please enter at least 3 characters",
                              },
                            })}
                            maxLength={30}
                            value={formData.name}
                            onChange={(e) => {
                              setFormData({ ...formData, name: e.target.value });
                              clearErrors(e.target.name);
                            }}
                          />
                          {errors.name && <span className="invalid">{errors.name.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Email <Required />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="email"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "This is required",
                              pattern: {
                                value:
                                  // eslint-disable-next-line
                                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                message: "Enter a valid email address",
                              },
                              maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                            })}
                            value={formData.email}
                            onChange={(e) => {
                              setFormData({ ...formData, email: e.target.value });
                              clearErrors(e.target.email);
                            }}
                          />
                          {errors.email && <span className="invalid">{errors.email.message}</span>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Contact Number <Required />
                          </label>
                          <div className="form-control-wrap d-flex align-center">
                            <Select
                              className="w-45"
                              value={selectedCountry}
                              options={countries}
                              onChange={handleChange}
                              styles={{
                                option: (provided, state) => ({
                                  ...provided,
                                  color: state.isSelected ? "white" : "black",
                                  backgroundColor: state.isSelected ? "#007bff" : "white",
                                  display: "flex",
                                  alignItems: "center",
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  display: "flex",
                                  alignItems: "center",
                                }),
                              }}
                              components={{
                                Option,
                                SingleValue,
                              }}
                            />
                            <input
                              type="number"
                              bssize="lg"
                              id="phone_number"
                              tabIndex={3}
                              value={formData?.phone_number}
                              {...register("phone_number", {
                                required: "this field is required",
                                pattern: {
                                  value: /^\d{10,15}$/,
                                  message: "Enter a valid Phone Number (10-15 digits)",
                                },
                              })}
                              onChange={(e) => {
                                setFormData({ ...formData, phone_number: e.target.value });
                                clearErrors(e.target.phone_number);
                              }}
                              maxLength={15} // Enforce max length at the HTML level
                              onInput={(e) => {
                                if (e.target.value.length > 15) {
                                  e.target.value = e.target.value.slice(0, 15); // Trim value to 15 digits if exceeded
                                }
                              }}
                              className="form-control-lg bg-transparent border form-control"
                              placeholder="Enter your contact number"
                            />
                          </div>
                          {errors.phone_number && (
                            <span className="ff-italic text-danger" style={{ fontSize: "11px" }}>
                              {errors.phone_number.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Event Name <Required />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="event_name"
                            placeholder="Enter Event Name"
                            {...register("event_name", {
                              required: "This field is required",
                              pattern: {
                                value: /^[A-Za-z\s'".]+$/,
                                message: "Only alphabets and spaces are allowed",
                              },
                              minLength: {
                                value: 3,
                                message: "Please enter at least 3 characters",
                              },
                            })}
                            value={formData.event_name}
                            onChange={(e) => {
                              setFormData({ ...formData, event_name: e.target.value });
                              clearErrors(e.target.event_name);
                            }}
                          />
                          {errors.event_name && <span className="invalid">{errors.event_name.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Event Type <Required />
                          </label>
                          <select
                            name="event_type"
                            id="event_type"
                            className="form-control"
                            value={formData.event_type}
                            {...register("event_type", {
                              required: "This field is required",
                            })}
                            onChange={(e) => {
                              setFormData({ ...formData, event_type: e.target.value });
                              clearErrors(e.target.event_type);
                            }}
                          >
                            <option value="" className=" p-0 m-0 my-1 p-1 rounded-0">
                              Select Event Type
                            </option>
                            {categoryListLoading ? (
                              <div className="mx-1 align-center">
                                <Spinner size="sm" color="light" />
                                <span className="px-1">Loading...</span>
                              </div>
                            ) : categoryListError ? (
                              <div>Error loading type. Please try again.</div>
                            ) : (
                              categoryList?.data?.Category &&
                              categoryList?.data?.Category.map((item, idx) => (
                                <option key={idx} value={item?.id} className=" p-0 m-0 my-1 p-1 rounded-0">
                                  {item?.title}
                                </option>
                              ))
                            )}
                            <option value="other" className=" p-0 m-0 my-1 p-1 rounded-0">
                              Other Type
                            </option>
                          </select>
                          {errors.event_type && <span className="invalid">{errors.event_type.message}</span>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Services/Packages <Required />
                          </label>
                          <select
                            name="event_type"
                            id="event_type"
                            className="form-control"
                            value={formData.event_package}
                            {...register("event_package", {
                              required: "This field is required",
                            })}
                            onChange={handlePackagePrice}
                          >
                            <option value=" " className=" p-0 m-0 my-1 p-1 rounded-0">
                              Select Package
                            </option>
                            {packageDataLoading ? (
                              <div className="mx-1 align-center">
                                <Spinner size="sm" color="light" />
                                <span className="px-1">Loading...</span>
                              </div>
                            ) : packageDataError ? (
                              <div>Error loading package. Please try again.</div>
                            ) : (
                              packageData?.data?.packages &&
                              packageData?.data?.packages.map((item, idx) => (
                                <option key={idx} value={item?.id} className=" p-0 m-0 my-1 p-1 rounded-0">
                                  {item?.package_name}
                                </option>
                              ))
                            )}
                            <option value="other" className=" p-0 m-0 my-1 p-1 rounded-0">
                              Other Package
                            </option>
                          </select>
                          {errors.event_package && <span className="invalid">{errors.event_package.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Packages Amount <Required />
                          </label>
                          <input
                            disabled={formData.event_package !== "other"}
                            className="form-control"
                            type="number"
                            id="event_package_amount"
                            placeholder="$ Enter Package"
                            // {...register("event_package_amount", {
                            //   required: "This field is required",
                            // })}
                            value={formData.event_package_amount}
                            onChange={(e) => {
                              if (e.target.value.length <= 5) {
                                setFormData({ ...formData, event_package_amount: e.target.value });
                              } else {
                                blockNumbers();
                              }
                            }}
                          />
                          {/* {errors.event_package_amount && (
                            <span className="invalid">{errors.event_package_amount.message}</span>
                          )} */}
                        </div>
                      </div>
                      {formData.event_package && formData.event_package !== "other" ? (
                        <TooltipComponent
                          id="event_package_amount"
                          text={"Either select package or fixed amount of packages"}
                          direction="top"
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <div className="form-group">
                    <div className="form-control-wrap my-2">
                      <label htmlFor="event_message">Event Message</label>
                      <textarea
                        className="form-control h-100px"
                        type="text"
                        id="event_message"
                        placeholder="Enter Event Message"
                        {...register("event_message", {
                          pattern: {
                            message: "Only alphabets and spaces are allowed",
                          },
                          minLength: {
                            value: 5,
                            message: "Please enter at least 5 characters",
                          },
                        })}
                        maxLength={500}
                        value={formData.event_message}
                        onChange={(e) => setFormData({ ...formData, event_message: e.target.value })}
                      />
                      {errors.event_message && <span className="invalid">{errors.event_message.message}</span>}
                    </div>
                  </div>
                  <div className="justify-center">
                    {loading ? (
                      <Button disabled color="primary" className="justify-center w-100 rounded">
                        <Spinner size="sm" />
                        <span> Loading... </span>
                      </Button>
                    ) : (
                      <Button color="primary" className="justify-center w-100 rounded" type="submit">
                        Send Inquiry
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </Card>
          </CardBody>
        </Card>
      </Block>
    </div>
  );
};
export default DjEnquriy;
